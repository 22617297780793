import React, {Component} from 'react';
import Form from 'react-bootstrap/Form'
import {FormGroup} from "react-bootstrap";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ArticleCard from "./ArticleCard/ArticleCard";
import ShopPagination from "./ShopPagination/ShopPagination";
import axios from 'axios'
import {API_URL} from "../Constants";
import Spinner from "react-bootstrap/Spinner";
import InputGroup from "react-bootstrap/InputGroup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch, faHourglassHalf} from '@fortawesome/free-solid-svg-icons'
import OrderLimitNotice from "../CheckoutPage/OrderLimitNotice";

class ShopSelection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            articles: [],
            currentPage: 1,
            search: "",
            loading: true,
            firstSearch: true,
            lastRequestTimestamp: 0,
            categories: [],
            groups: [],
            subgroups: [],
            selectedCategory: '',
            selectedGroup: '',
            selectedSubGroup: '',
            loadingCategories: true
        };
        this.searchArticles();
        this.loadCategories();
    }

    loadCategories = () => {
        axios({
            method: 'get',
            url: API_URL + 'categories',
            params: {
                'tree': 'yes'
            },
            config: {headers: {"Access-Control-Allow-Origin": "*"}},
            withCredentials: true
        })
            .then(response => {
                //handle success
                this.setState({
                    categories: response.data,
                    loadingCategories: false
                });
            });
    };

    searchArticles = () => {
        let thisTimestamp = Date.now();
        if (!this.state.firstSearch) {
            this.setState({
                lastRequestTimestamp: thisTimestamp,
                loading: true
            });
        }
        setTimeout(() => {
            if (this.state.lastRequestTimestamp <= thisTimestamp) {
                axios({
                    method: 'get',
                    url: API_URL + 'searcharticles',
                    params: {
                        'search': this.state.search,
                        'category': this.state.selectedCategory,
                        'group': this.state.selectedGroup,
                        'subgroup': this.state.selectedSubgroup
                    },
                    config: {headers: {"Access-Control-Allow-Origin": "*"}},
                    withCredentials: true
                })
                    .then(response => {
                        //handle success
                        if (this.state.lastRequestTimestamp <= thisTimestamp) {
                            this.setState({
                                articles: response.data,
                                currentPage: 1,
                                loading: false,
                                firstSearch: false
                            });
                        }
                    });
            }
        }, 300)
    };

    changeCurrentPage = (newPageNumber) => {
        this.setState({
                currentPage: newPageNumber
            }
        )
    };

    updateSearchTerm = (event) => {
        this.setState({
            search: event.target.value
        }, () => {
            this.searchArticles()
        });
    };

    updateCategory = (event) => {
        let newValue = event.target.value;
        let groups = [];
        for (const category of this.state.categories) {
            if (category.id === parseInt(newValue)) {
                groups = category.product_groups;
                break;
            }
        }
        if (this.state.selectedCategory !== newValue) {
            this.setState({
                selectedCategory: newValue,
                selectedGroup: "",
                selectedSubgroup: "",
                groups: groups,
                subgroups: []
            }, this.searchArticles);

        }
    };

    updateGroup = (event) => {
        let newValue = event.target.value;
        let subgroups = [];
        for (const group of this.state.groups) {
            if (group.id === parseInt(newValue)) {
                subgroups = group.product_subgroups;
                break;
            }
        }
        if (this.state.selectedGroup !== newValue) {
            this.setState({
                selectedGroup: newValue,
                selectedSubgroup: "",
                subgroups: subgroups
            }, this.searchArticles);

        }
    };

    updateSubgroup = (event) => {
        let newValue = event.target.value;
        if (this.state.selectedSubgroup !== newValue) {
            this.setState({
                selectedSubgroup: newValue,
            }, this.searchArticles);

        }
    };

    shouldShowGroupSelect = () => {
        return this.state.selectedCategory && this.state.groups && this.state.groups.length > 0;
    };

    shouldShowSubgroupSelect = () => {
        return this.state.selectedGroup && this.state.subgroups && this.state.subgroups.length > 0;
    };

    render() {
        let numberOfPages = Math.ceil(this.state.articles.length / 12);
        let currentPage = this.state.currentPage;
        let articlesToDisplay = this.state.articles.slice((currentPage - 1) * 12, currentPage * 12);
        return (
            <React.Fragment>
                <FormGroup>
                    <Form.Label className="mt-2">Izvolite :</Form.Label>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faSearch}/>
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control value={this.state.search} placeholder="Unesite naziv artikla koji tražite"
                                      onChange={this.updateSearchTerm} size="lg" autoFocus>
                        </Form.Control>
                    </InputGroup>
                </FormGroup>
                <Form.Group as={Row}>
                    <Col lg={this.shouldShowSubgroupSelect() ? 4 : this.shouldShowGroupSelect() ? 6 : 12} md={12}>
                        <Form.Group>

                            <InputGroup>
                                {this.state.loadingCategories && <InputGroup.Prepend>
                                    <InputGroup.Text>
                                        <FontAwesomeIcon icon={faHourglassHalf}/>
                                    </InputGroup.Text>
                                </InputGroup.Prepend>}
                                <Form.Control as="select" value={this.state.selectedCategory}
                                              disabled={this.state.loadingCategories}
                                              onChange={this.updateCategory}>
                                    {this.state.categories.map((category, index) => {
                                        return (
                                            <option key={"category" + index}
                                                    value={category.id}>{category.name}</option>
                                        )
                                    })}
                                    <option value={''}>Prikaz svih kategorija proizvoda</option>
                                </Form.Control>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    {this.shouldShowGroupSelect() && <Col lg={this.shouldShowSubgroupSelect() ? 4 : 6} md={12}>
                        <Form.Group>
                            <Form.Control as="select" value={this.state.selectedGroup} onChange={this.updateGroup}>
                                {this.state.groups.map((group, index) => {
                                    return (
                                        <option key={"group" + index} value={group.id}>{group.name}</option>
                                    )
                                })}
                                <option value={""}>Prikaz svih grupa proizvoda</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>}
                    {this.shouldShowSubgroupSelect() && <Col lg={4} md={12}>
                        <Form.Group>
                            <Form.Control as="select" value={this.state.selectedSubgroup}
                                          onChange={this.updateSubgroup}>
                                {this.state.subgroups.map((subgroup, index) => {
                                    return (
                                        <option key={"subgroup" + index} value={subgroup.id}>{subgroup.name}</option>
                                    )
                                })}
                                <option value={""}>Prikaz svih podgrupa proizvoda</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>}
                </Form.Group>
                {this.state.loading && <Spinner animation="border" variant="primary" size="lg"
                                                style={{
                                                    height: "150px",
                                                    width: "150px",
                                                    align: "center",
                                                    marginLeft: "auto",
                                                    marginRight: "auto",
                                                    display: "block"
                                                }}/>}
                <OrderLimitNotice/>
                {!this.state.loading && <React.Fragment>
                    <ShopPagination currentPage={this.state.currentPage}
                                    numberOfPages={numberOfPages}
                                    pageChangeCallback={this.changeCurrentPage}/>
                    <Row>
                        {articlesToDisplay.map((article, index) =>
                            <Col xl={4} lg={4} md={6} sm={12} xs={12} className="mb-4" key={index}>
                                <ArticleCard article={article}/>
                            </Col>
                        )}
                    </Row>
                    <ShopPagination currentPage={this.state.currentPage}
                                    numberOfPages={numberOfPages}
                                    pageChangeCallback={this.changeCurrentPage}/>
                </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

export default ShopSelection;
