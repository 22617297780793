import React, {Component} from 'react';
import ReactDOM from "react-dom";
import Card from "react-bootstrap/Card";
import Acordion from 'react-bootstrap/Accordion'
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";

class HomePage extends Component {

    componentDidMount() {
        let g = document.createElement('script');
        g.setAttribute('src', "https://consent.cookiebot.com/129ae980-b31e-43d4-8f36-172123ccb524/cd.js");
        g.setAttribute('id', "CookieDeclaration");
        g.setAttribute('type', "text/javascript");
        g.setAttribute('async', "True");
        ReactDOM.findDOMNode(this.refs.cookies).appendChild(g);
    }

    render() {
        return (
            <React.Fragment>
                <header className="py-5 bg-image-full"
                        style={{
                            backgroundImage: 'url(images/store1.jpg)',
                            backgroundSize: 'cover',
                            backgroundPosition: "center center"
                        }}>
                    <br/>
                    <h3 style={{
                        color: 'white',
                        textShadow: '1px 1px #000000, -1px -1px #000000,-1px 1px #000000,1px -1px #000000'
                    }} className="text-center"> Prodavnica koja dolazi do Vas</h3>
                    <br/>
                    <h1 style={{
                        color: 'white',
                        textShadow: '1px 1px #000000, -1px -1px #000000,-1px 1px #000000,1px -1px #000000'
                    }} className="text-center"><strong>Mikromarket</strong> online</h1>
                    <br/>
                    <h5 style={{
                        color: 'white',
                        textShadow: '1px 1px #000000, -1px -1px #000000,-1px 1px #000000,1px -1px #000000'
                    }} className="text-center">Brza dostava svakog dana</h5>
                    <h6 style={{
                        color: 'white',
                        textShadow: '1px 1px #000000, -1px -1px #000000,-1px 1px #000000,1px -1px #000000'
                    }} className="text-center">pon-ned 00h-24h</h6>
                </header>
                <div className="container">
                    <br/>
                    <h4>Šta je "Mikromarket online"?</h4>
                    <p className="text-justify">
                        "Mikromarket online" je servis koji Vam omogućava da obavite svoju kupovinu
                        iz udobnosti svog doma. Ukoliko iz bilo kog razloga ne možete, ili ne želite sami da idete do
                        prodavnice možete preko našeg servisa napraviti spisak i neko od naših saradnika će u
                        najkraćem mogućem roku obaviti kupovinu za Vas i dostaviti Vašu narudžbinu na Vašu adresu na
                        većini teritorije Novog Sada, Petrovaradina ili Sremske Kamenice.
                    </p>
                    <h4>Kako funkcioniše?</h4>
                    <p className="text-justify">
                        Kada napravite spisak, neko od naših saradnika će obaviti kupovinu za
                        Vas i pozvati kurira da obavi dostavu. Ukoliko naš saradnik ne uspe da nađe neki od artikala
                        koje ste poručili,
                        ili ga pronađe po ceni drugačijoj od istaknute, pozvaće Vas da Vas upita da
                        li želite neki drugi artikal, ostatak porudžbine bez tog artikla, ili da odustanete od kupovine.
                        Ako se na Vašoj listi želja nalaze rinfuzni artikli, naš saradnik će se potruditi da kupljena
                        količina bude što bliža naručenoj količini. Kod rinfuznih artikala se naplaćuje stvarna
                        količina, a ne naručena količina. Stvarna količina se nikada neće razlikovati od naručene za
                        više od 10%.
                    </p>
                    <h4>Kako se plaća?</h4>
                    <p className="text-justify">
                        Plaćanje se vrši gotovniski, dostavljaču pri preuzimanju pošiljke. Za
                        sada ne postoji mogućnost drugih načina plaćanja.
                    </p>
                    <h4>Šta ako ne budem prisutan/na da preuzmem porudžbinu?</h4>
                    <p className="text-justify">
                        Porudžbinu za Vas može preuzeti i neka druga osoba. Ukoliko porudžbinu
                        niko ne preuzme, dostavljač će je vratiti u našu centralu. Da biste imali pravo ponovo da
                        koristite usluge "Mikromarket online" morate nadoknaditi štetu u iznosu cene dostave i vrednosti
                        sve
                        kvarljive robe u porudžbini kao što su riba, meso, voće, povrće i sl.
                    </p>
                    <h4>Spreman/na sam da kupujem</h4>
                    <p className="text-justify">
                        Kliknite <Link to="/"> <strong>ovde</strong></Link> da biste
                        otišli u našu prodavnicu .
                    </p>
                    <h4>Kontakt</h4>
                    <p className="text-justify">
                        Za sve dodatne informacije, sugestije i eventualne primedbe molimo vas da nas kontaktirate na <br/>
                        Telefon: 0648731156 <br/>
                        e-mail: online@mikromarket.rs
                    </p>
                    <Acordion>
                        <Card>
                            <Card.Header>
                                <Acordion.Toggle as={Button} variant="link" eventKey="0">
                                    Deklaracija o kolačićima
                                </Acordion.Toggle>
                            </Card.Header>
                            <Acordion.Collapse eventKey="0">
                                <Card.Body>
                                    <div ref='cookies'>
                                    </div>
                                </Card.Body>
                            </Acordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Acordion.Toggle as={Button} variant="link" eventKey="1">
                                    Politika privatnosti i uslovi korišćenja
                                </Acordion.Toggle>
                            </Card.Header>
                            <Acordion.Collapse eventKey="1">
                                <Card.Body>
                                    <div>
                                        <h6> Saglasnost</h6>
                                        <p>
                                            Korišćenjem usluga "Mikromarket online" dajete saglasnost sa uslovima
                                            korišćenja i
                                            politikom privatnosti. "Mikromarket d.o.o." i druga pravna lica
                                            povezana
                                            sa uslugom koju pruža "Mikromarket online" zadržavaju pravo da izmene uslove
                                            korišćenja i politiku privatnosti bez prethodnog obaveštenja.
                                        </p>
                                        <h6> Koje podatke prikupljamo?</h6>
                                        <p>
                                            "Mikromarket online" prikuplja samo podatke neophodne za potrebe poslovanja.
                                            Prilikom posete sajta "Mikromarket.rs" prikupljamo podatke o Vašoj
                                            poseti i
                                            Vašoj aktivnosti na sajtu, ali ne Vaše lične podatke. Prilikom
                                            korišćenja
                                            usluga, pored sadržaja porudžbenice, neophodno je da nam date adresu na
                                            koju
                                            će Vaša porudžbina biti dostavljena kao i broj mobilnog telefona. Pored
                                            toga
                                            možete nam dati i podatke o svom imenu koji nisu obavezni ali nam mogu
                                            pomoći u poboljšanju usluge koju Vam pružamo.
                                        </p>
                                        <h6> Ko ima pristup Vašim podacima?</h6>
                                        <p>
                                            Podatke o sadržaju Vaše porudžbine, Vaše ime ukoliko ste nam taj podatak
                                            pružili, broj telefona i adresu delimo sa partnerima koji vrše nabavku
                                            sadržaja Vaše porudžbine i njenu dostavu, kao i partnerima koji razvijaju i
                                            održavaju web platformu za online kupovinu. Pored njih, Vaši podaci su
                                            preko
                                            forme na veb sajtu "Mikromarket.rs" dostupni svima koji znaju
                                            kombinaciju
                                            Vašeg broja telefona i verifikacionog koda koji je dodeljen Vašoj
                                            porudžbini.
                                        </p>
                                        <h6> Koliko dugo zadržavamo Vaše podatke?</h6>
                                        <p>
                                            "Mikromarket online" Vaše i podatke o Vašoj porudžbini zadržavaju
                                            7
                                            dana
                                            u bazi podataka. Nakon tog perioda iz baze se brišu ime, broj telefona i
                                            adresa osim
                                            naziva ulice. Naziv ulice, sadržaj porudžbine i vremenski pečati
                                            povezani sa
                                            porudžbinom se zadržavaju u bazi podataka radi održavanja statistike i
                                            poboljšanja usluga. Nakon 7 dana Vaši podaci prestaju da budu dostupni
                                            preko
                                            forme na "Mikromarket.rs". Korespodencija između "Mikromarket online" i
                                            partnera koja sadrži Vaše podatke se čuva do dva meseca
                                            ukoliko
                                            nije predmet spora između neke od strana. U tom slučaju se čuva do
                                            razrešenja spora.
                                        </p>
                                    </div>
                                </Card.Body>
                            </Acordion.Collapse>
                        </Card>
                        {/*<Card>*/}
                        {/*<Card.Header>*/}
                        {/*<Acordion.Toggle as={Button} variant="link" eventKey="2">*/}
                        {/*Kontakt podaci*/}
                        {/*</Acordion.Toggle>*/}
                        {/*</Card.Header>*/}
                        {/*<Acordion.Collapse eventKey="2">*/}
                        {/*<Card.Body>*/}
                        {/*<p className="text-justify">*/}
                        {/*Rolas Šabac <br/>*/}
                        {/*Karađorđeva 54 <br/>*/}
                        {/*15000 Šabac <br/>*/}
                        {/*PIB: 110269470 <br/>*/}
                        {/*MB: 64773291 <br/>*/}
                        {/*</p>*/}
                        {/*</Card.Body>*/}
                        {/*</Acordion.Collapse>*/}
                        {/*</Card>*/}
                    </Acordion>
                </div>
                <br/>
                {/*<footer className="footer border-top">*/}
                {/*<br/>*/}
                {/*<ContactForm/>*/}
                {/*</footer>*/}
            </React.Fragment>
        )
    }
}

export default HomePage;
