import React from 'react';

export const CartContext = React.createContext({
    articles: [],
    addArticle: {},
    calculateCartCost: {},
    removeArticle: {},
    emptyCart: {}
});

export const OrdersContext = React.createContext({
    phoneNumber: [],
    fetchOrder: {},
    openConfirmModal: {},
    closeConfirmModal: {}
});

export const AuthContext = React.createContext({
    getAuthString: {},
    logout: {}
});