import React, {Component} from 'react';
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import './App.css';
import ShopSelection from './ShopSelection/ShopSelection';
import {CartContext, OrdersContext} from './Contexts'
import Form from 'react-bootstrap/Form'
import {BrowserRouter as Router, Route, Link} from "react-router-dom";
import HomePage from "./HomePage/HomePage";
import CheckoutPage from "./CheckoutPage/CheckoutPage";
import OrdersPage from "./OrdersPage/OrdersPage";
import axios from "axios";
import {API_URL} from "./Constants";
import AuthenticationScreen from "./StorePortal/AuthenticationScreen";
import CheckoutRedirectButton from "./CheckoutPage/CheckoutRedirectButton";
import logo from './mikromarket-logo-white.png'
import DeliveryPricelist from "./DeliveryPricelist/DeliveryPricelist";
import InfoPopup from "./InfoPopup/InfoPopup";

class App extends Component {

    constructor(props) {
        super(props);
        const cartFromStorage = localStorage.getItem('cart');
        let indexedCart = this.parseCartFromStorage(cartFromStorage);
        this.state = {
            showInfoModal: false,
            cart: {
                articles: indexedCart,
                addArticle: this.addArticleToCart,
                calculateCartCost: this.calculateCartCost,
                removeArticle: this.removeArticleFromCart,
                emptyCart: this.emptyCart
            },
            orders: {
                order: {},
                fetchOrder: this.fetchOrder,
                confirmModalOpen: false,
                loading: false,
                openConfirmModal: this.openConfirmModal,
                closeConfirmModal: this.closeConfirmModal,
                errorOnFetch: false
            }
        };
        window.addEventListener('storage', this.updateCartFromLocalStorage);
    }

    updateCartFromLocalStorage = (e) => {
        if(e.key==='cart') {
            const cartFromStorage = e.newValue;
            const indexedCart = this.parseCartFromStorage(cartFromStorage);
            const cart = this.state.cart;
            cart.articles = indexedCart;
            this.setState({
                cart: cart
            })
        }
    };

    parseCartFromStorage = (cartString) => {
        let indexedCart = [];
        if (cartString) {
            const nonIndexedCart = JSON.parse(cartString);
            for (const entry of nonIndexedCart) {
                indexedCart[entry.article.id] = entry;
            }
        }
        return indexedCart;
    };

    addArticleToCart = (articleId, amount, article) => {
        let cart = this.state.cart;
        if (cart.articles[articleId]) {
            cart.articles[articleId].amount += amount;
        } else {
            cart.articles[articleId] = {amount: amount, article: article};
        }
        if (cart.articles[articleId].amount <= 0) {
            delete cart.articles[articleId]
        } else {
            cart.articles[articleId].amount = Math.round(cart.articles[articleId].amount * 100) / 100;
        }
        this.setState({cart: cart}, this.saveCartToLocalStorage);
    };

    saveCartToLocalStorage = () => {
        const filtered = this.state.cart.articles.filter((e) => {
            return e != null;
        });
        localStorage.setItem('cart', JSON.stringify(filtered));
    };

    hideInfoModal = () => {
        this.setState({
            showInfoModal: false
        })
    }

    render() {
        return (
            <Router>
                <InfoPopup show={this.state.showInfoModal} onHide={this.hideInfoModal}/>
                <CartContext.Provider value={this.state.cart}>
                    <OrdersContext.Provider value={this.state.orders}>
                        <Navbar collapseOnSelect bg="primary" variant="dark" expand="lg" fixed='top'
                                style={{paddingBottom: "0px", paddingTop: "0px", minHeight: "40px"}}>
                            <Container>
                                <Navbar.Brand as={Link} to="/">
                                    <img src={logo} style={{height: "20px"}} alt=""/>
                                </Navbar.Brand>
                                <Navbar.Brand as={Link} to="/">
                                    00-24
                                </Navbar.Brand>
                                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="mr-auto">
                                        <Nav.Link as={Link} to="/" href="/">Prodavnica</Nav.Link>
                                        <Nav.Link as={Link} to="/checkout" href="/checkout">Kasa</Nav.Link>
                                        <Nav.Link as={Link} to="/orders" href="/orders">Porudžbine</Nav.Link>
                                        <Nav.Link as={Link} to="/deliverypricelist" href="/orders">Cenovnik
                                            dostave</Nav.Link>
                                        <Nav.Link as={Link} to="/about" href="/about">O Nama</Nav.Link>
                                    </Nav>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>
                        <Container id="no-more-tables">
                            <Route path="//" component={ShopSelection}/>
                            <Route path="/checkout" component={CheckoutPage}/>
                            <Route path="/orders" component={OrdersPage}/>
                            <Route path="/deliverypricelist" component={DeliveryPricelist}/>
                        </Container>
                        <Route path="/storeportal" component={AuthenticationScreen}/>
                        <Route path="/about" component={HomePage}/>
                        {this.shouldDisplayFooter() &&
                        <Navbar bg="primary" variant="dark" expand="lg" fixed='bottom'>
                            <Container>
                                <Navbar.Text>
                                    Cena : {this.formatCartCost()} + dostava
                                </Navbar.Text>
                                <Form inline>
                                    <CheckoutRedirectButton/>
                                </Form>
                            </Container>
                        </Navbar>
                        }
                    </OrdersContext.Provider>
                </CartContext.Provider>
            </Router>
        );
    }

    shouldDisplayFooter = () => {
        return this.calculateCartCost() !== 0;
    };

    formatCartCost = () => {
        let totalCost = this.calculateCartCost();
        return (totalCost).toLocaleString("sr-RS", {style: "currency", currency: "RSD", minimumFractionDigits: 2})
    };

    calculateCartCost = () => {
        let cart = this.state.cart.articles;
        let totalCost = 0;
        for (let cartMemberId in cart) {
            let cartMember = cart[cartMemberId];
            totalCost += cartMember.amount * cartMember.article.price;
        }
        return totalCost;
    };

    removeArticleFromCart = (articleId) => {
        let cart = this.state.cart;
        delete cart.articles[articleId];
        this.setState({cart: cart}, this.saveCartToLocalStorage);
    };

    emptyCart = () => {
        let cart = this.state.cart;
        cart.articles = [];
        this.setState({cart: cart}, this.saveCartToLocalStorage);
    };

    fetchOrder = (phoneNumber, verificationCode) => {
        let orders = this.state.orders;
        orders.loading = true;
        this.setState({orders: orders});
        axios({
            method: 'get',
            url: API_URL + 'getorder',
            params: {
                'phoneNumber': phoneNumber,
                'verificationCode': verificationCode
            },
            config: {headers: {"Access-Control-Allow-Origin": "*"}}
        })
            .then(response => {
                //handle success
                orders.order = response.data;
                orders.loading = false;
                orders.errorOnFetch = false;
                this.setState({orders: orders});
            })
            .catch(error => {
                orders.loading = false;
                orders.errorOnFetch = true;
                console.log("erro on fetch");
                this.setState({orders: orders});
            });
    };

    openConfirmModal = () => {
        let orders = this.state.orders;
        orders.confirmModalOpen = true;
        this.setState({
            orders: orders
        })
    };

    closeConfirmModal = () => {
        let orders = this.state.orders;
        orders.confirmModalOpen = false;
        this.setState({
            orders: orders
        })
    }
}

export default App;
