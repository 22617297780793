import React, {Component} from 'react';
import Pagination from "react-bootstrap/Pagination"
import ShopPaginationItem from "./ShopPaginationItem/ShopPaginationItem";

class ShopPagination extends Component {

    render() {
        let pageItems = [];
        for (let i = 1; i <= this.props.numberOfPages; i++) {
            pageItems.push(
                <ShopPaginationItem active={this.props.currentPage === i} pageNumber={i}
                                    pageChangeCallback={this.props.pageChangeCallback}
                                    key={i}/>
            )
        }

        return (
            this.props.numberOfPages > 1 &&
            <Pagination className="justify-content-center">
                {pageItems}
            </Pagination>
        )
    }
}

export default ShopPagination;