let url = "https://api.novimarket.net/api/";
if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1")
    url = "http://localhost:8000/api/";
else if (window.location.hostname.startsWith("192.168."))
    url = "http://" + window.location.hostname + ":8000/api/";
export const API_URL = url;


export const ORDER_LIMITS = {
    disallowOrder: 25,
    moreThan60minutes: 10,
    moreThan90Minutes: 15,
    moreThan120Minutes: 20,
    maxPerHour: 40
};

export const MISSING_ARTICLE_ACTIONS = {
    CANCEL: "CANCEL",
    REPLACE: "REPLACE",
    IGNORE: "IGNORE"
};
