import React, {Component} from 'react';
import Button from "react-bootstrap/Button";
import Select from 'react-select';
import axios from "axios";
import {API_URL} from "../../Constants";
import Form from 'react-bootstrap/Form'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from '@fortawesome/free-solid-svg-icons'
import {AuthContext} from "../../Contexts";

class NewOrderlineRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            selectedArticle: null,
            options: [],
            amount: 1
        };
    }

    openNewOrder = () => {
        this.setState({
            open: true,
        })
    };

    closeNewOrder = () => {
        this.setState({
            open: false,
            selectedArticle: null
        })
    };

    handleChoiceChange = (selectedOption) => {
        this.setState({
            selectedArticle: selectedOption
        });
    };

    handleInputChange = (input) => {
        if (input) {
            let thisTimestamp = Date.now();
            if (!this.state.firstSearch) {
                this.setState({
                    lastRequestTimestamp: thisTimestamp,
                });
            }
            setTimeout(() => {
                if (this.state.lastRequestTimestamp <= thisTimestamp) {
                    axios({
                        method: 'get',
                        url: API_URL + 'searcharticlesupc',
                        params: {
                            'search': input
                        },
                        headers: {
                            'Authorization': this.context.getAuthString()
                        },
                    })
                        .then(response => {
                            //handle success
                            if (this.state.lastRequestTimestamp <= thisTimestamp) {
                                var new_options = [];
                                response.data.forEach((element) => {
                                    new_options.push({
                                        value: element,
                                        label: element.universal_product_code + " | " + element.name + " | " + element.price + " RSD"
                                    })
                                });
                                this.setState({
                                    options: new_options
                                });
                            }
                        })
                        .catch(error => {
                            console.log(error);
                        })
                }
            }, 300)
        }
    };

    filterOption = (object, string) => {
        return true;
    };

    handleAmountChange = (event) => {
        this.setState({
            amount: parseFloat(event.target.value)
        });
    };

    saveOrderline = () => {
        let onSuccess = this.props.onSuccess;
        this.props.startLoad();
        const endLoad = this.props.endLoad;
        axios.post(API_URL + 'orders/' + this.props.order.id + '/addorderline', {
            articleId: this.state.selectedArticle.value.id,
            amount: this.state.amount
        }, {
            headers: {
                'Authorization': this.context.getAuthString()
            }
        })
            .then(response => {
                if (onSuccess)
                    onSuccess(this.props.order.id, response.data);
                this.closeNewOrder();
                endLoad();
            })
            .catch(error => {
                console.log(error);
                alert("Akcija nije uspela");
                endLoad();
            })
    };

    formatPrice = (price) => {
        return price.toLocaleString("sr-RS", {style: "currency", currency: "RSD", minimumFractionDigits: 2})
    };

    render() {
        if (!this.props.order.fulfilled && !this.props.order.canceled) {
            return (
                <React.Fragment>
                    {
                        this.state.open &&
                        <tr>
                            <td>
                                <Select
                                    value={this.state.selectedArticle}
                                    onChange={this.handleChoiceChange}
                                    options={this.state.options}
                                    onInputChange={this.handleInputChange}
                                    filterOption={this.filterOption}
                                />
                            </td>
                            <td>
                                <Form.Control
                                    type="number"
                                    step={this.state.selectedArticle && this.state.selectedArticle.value.amount_step}
                                    value={this.state.amount}
                                    onChange={this.handleAmountChange}
                                />
                            </td>
                            <td>{this.state.selectedArticle && this.state.selectedArticle.value.purchasing_amount_type}</td>
                            <td>{this.state.selectedArticle && this.formatPrice(this.state.selectedArticle.value.price)}</td>
                            <td>{this.state.selectedArticle && this.formatPrice(this.state.selectedArticle.value.price * this.state.amount)}</td>
                            <td>
                                <Button className="mr-3" disabled={!this.state.selectedArticle || !this.state.amount}
                                        onClick={this.saveOrderline}>
                                    <FontAwesomeIcon icon={faCheck}/>
                                </Button>
                                <Button variant="danger" className="mr-3" onClick={this.closeNewOrder}>
                                    <FontAwesomeIcon icon={faTimes}/>
                                </Button>
                            </td>
                        </tr>
                    }
                    {
                        !this.state.open &&
                        <tr>
                            <td>

                            </td>
                            <td/>
                            <td/>
                            <td/>
                            <td/>
                            <td>
                                <Button onClick={this.openNewOrder}>Dodaj artikal</Button>
                            </td>
                        </tr>
                    }
                </React.Fragment>
            )
        } else {
            return null;
        }
    }
}

NewOrderlineRow.contextType = AuthContext;

export default NewOrderlineRow;
