import React, {Component} from 'react'
import {API_URL, ORDER_LIMITS} from '../Constants'
import Alert from "react-bootstrap/Alert";
import axios from "axios";

class OrdersPerHourLimitNotice extends Component {

    constructor(props) {
        super(props);
        this.state = {
            numberOfOrdersInLastHour: 0
        }
        this.fetchNumberOfOrders()
        setInterval(this.fetchNumberOfOrders, 60000)
    }

    fetchNumberOfOrders = () => {
        axios({
            method: 'get',
            url: API_URL + 'ordersnumberhour',
            config: {headers: {"Access-Control-Allow-Origin": "*"}},
            withCredentials: true
        })
            .then(response => {
                this.setState({
                    numberOfOrdersInLastHour: response.data,
                });
            });
    }

    render() {
        if (this.state.numberOfOrdersInLastHour >= ORDER_LIMITS.maxPerHour) {
            return (<div>
                    <Alert variant="danger">
                        Zbog prebukiranosti kapaciteta trenutno nismo u mogućnosti da primimo vašu narudžbinu. Molimo
                        pokušajte kasnije.
                    </Alert>
                </div>
            )
        } else return null
    }

}

export default OrdersPerHourLimitNotice;
