import React, {Component} from 'react';
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import Button from 'react-bootstrap/Button'
import FormGroup from "react-bootstrap/FormGroup";
import Form from 'react-bootstrap/Form'
import * as PropTypes from "prop-types";
import axios from "axios";
import {API_URL} from "../../Constants";
import Recaptcha from 'react-recaptcha'
import {OrdersContext} from "../../Contexts";
import {withRouter} from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

class VerificationModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            verificationCode: "",
            verificationFailed: false,
            captchaPassed: false,
            loading: false
        };
    }

    sendVerification = () => {
        let temporaryCode = this.props.order.verification_code;
        this.setState({
            loading: true
        });
        axios.post(API_URL + 'verifyorder/', {
            orderId: this.props.order.id,
            verificationCode: this.props.order.verification_code
        })
            .then(response => {
                this.context.fetchOrder(this.props.phoneNumber, temporaryCode);
                this.context.openConfirmModal();
                console.log(response);
                this.props.onHide();
                if (this.props.verificationCallback) {
                    this.props.verificationCallback();
                }
                this.setState({
                    loading: true
                });
                this.props.history.push('/orders')
            })
            .catch(error => {
                this.setState({
                    verificationFailed: true
                });
                console.log(error);
                this.setState({
                    loading: false
                });
            });
        this.setState({
            verificationCode: ""
        })

    };

    verifyCallback = (response) => {
        console.log("verifyCallback");
        console.log(response);
        this.setState({
            captchaPassed: true
        });
        this.props.sendOrderCallback(response);
    };

    loadCallback = (response) => {
        console.log("loadCallback");
        console.log(response);
    };

    onHide = () => {
        this.setState({
            captchaPassed: false,
            verificationFailed: false
        });
        this.props.onHide()
    };

    render() {
        return <Modal show={this.props.show} onHide={this.onHide} backdrop='static'>
            <Modal.Header closeButton>
                <Modal.Title>Potvrda porudžbine</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!this.state.captchaPassed &&
                <Recaptcha
                    sitekey="6LehUrIUAAAAAJqEWhbvRUjlTl5O4Ixp4QdzOLCS"
                    verifyCallback={this.verifyCallback}
                    onloadCallback={this.loadCallback}
                    hl="sr"
                />
                }
                {this.state.captchaPassed && !this.state.loading &&
                <FormGroup>
                    <Form.Label>
                        Kod vaše porudžbine je: <strong>{this.props.order.verification_code}</strong>.
                    </Form.Label>
                    <Form.Label>
                        Kod služi isključivo za proveru sastava porudžbine i njenog trenutnog stanja.
                    </Form.Label>
                    <Form.Label>
                        Ukoliko ga zaboravite možete ga
                        proveriti u emailu poslatom
                        na <strong>{this.props.email}</strong>.
                    </Form.Label>
                    <Form.Label>
                        Molimo proverite i Spam/Nepoželjne poruke.
                    </Form.Label>
                    <Form.Label>
                        Za potvrdu porudžbine kliknite dugme potvrdi.
                    </Form.Label>
                </FormGroup>}
                {this.state.verificationFailed && this.state.captchaPassed && !this.state.loading &&
                <Alert variant="danger">
                    Porudžbina nije uspela. Pokušajte ponovo.
                </Alert>}
                {this.state.loading && <Spinner animation="border" variant="primary" size="lg"
                                                style={{
                                                    height: "150px",
                                                    width: "150px",
                                                    align: "center",
                                                    marginLeft: "auto",
                                                    marginRight: "auto",
                                                    display: "block"
                                                }}/>}
            </Modal.Body>
            {(this.state.captchaPassed && this.props.order.id) && !this.state.loading &&
            <Modal.Footer>
                <Button variant="primary" onClick={this.sendVerification}>
                    Potvrdi
                </Button>
            </Modal.Footer>
            }
        </Modal>;
    }
}

VerificationModal.propTypes = {
    show: PropTypes.any,
    onHide: PropTypes.func,
    email: PropTypes.any,
    order: PropTypes.any,
    sendOrderCallback: PropTypes.func,
    verificationCallback: PropTypes.func
};

VerificationModal.contextType = OrdersContext;

const VerificationModalWithRouter = withRouter(VerificationModal);

export default VerificationModalWithRouter;
