import React, {Component} from 'react';
import Pagination from "react-bootstrap/Pagination"

class ShopPaginationItem extends Component {

    changePage = () => {
        this.props.pageChangeCallback(this.props.pageNumber)
    };

    render() {
        return (
            <Pagination.Item active={this.props.active}
                             onClick={this.changePage}>{this.props.pageNumber}</Pagination.Item>
        )

    }
}

export default ShopPaginationItem;