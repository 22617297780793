import React, {Component} from 'react';
import {AuthContext} from "../Contexts";
import Spinner from "react-bootstrap/Spinner";
import {Form, FormGroup, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import LoadingModal from "../LoadingModal";
import Order from "./components/Order";
import Table from "react-bootstrap/Table";
import axios from "axios";
import {API_URL} from "../Constants";
import Fuse from "fuse.js";

class NonVerifiedOrdersTab extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            orders: [],
            loading: true,
            openOrderId: null,
            search: "",
            filteredOrders: [],
            loadingModalOpen: false
        };
        this.loadOrders();
        setInterval(this.reloadOrders, 60000);
    }

    loadOrders = () => {
        axios({
            method: 'post',
            url: API_URL + 'orders/',
            headers: {
                'Authorization': this.context.getAuthString()
            }
        })
            .then(response => {
                //handle success
                this.setState({
                    orders: response.data,
                    loading: false,
                    filteredOrders: response.data
                });
            });
    };

    reloadOrders = () => {
        axios({
            method: 'post',
            url: API_URL + 'orders/',
            headers: {
                'Authorization': this.context.getAuthString()
            }
        })
            .then(response => {
                //handle success
                this.setState({
                    orders: response.data,
                }, () => {
                    this.searchOrders()
                });
            });
    };

    markAsLoading = () => {
        this.setState({
            loadingModalOpen: true
        })
    };

    unmarkAsLoading = () => {
        this.setState({
            loadingModalOpen: false
        })
    };

    openOrder = (order) => {
        let orderId = order.id;
        let self = this;
        return function () {
            if (self.state.openOrderId === orderId) {
                self.setState({
                    openOrderId: null
                })
            } else {
                self.setState({
                    openOrderId: orderId
                })
            }
        }
    };

    updateSearchTerm = (event) => {
        this.setState({
            search: event.target.value,
        }, () => {
            this.searchOrders()
        });

    };

    searchOrders = () => {
        let result = this.state.orders;
        if (this.state.search) {
            const options = {
                shouldSort: true,
                threshold: 0.6,
                location: 0,
                distance: 100,
                maxPatternLength: 32,
                minMatchCharLength: 1,
                keys: [
                    "street",
                    "street_number"
                ]
            };
            const fuse = new Fuse(this.state.orders, options); // "list" is the item array
            result = fuse.search(this.state.search);
        }
        this.setState({
            filteredOrders: result
        });
    };

    verifyOrder = (order) => {
        return async () => {
            this.markAsLoading();
            try {
                await axios.post(API_URL + 'verifyorder/', {
                    orderId: order.id,
                    verificationCode: order.verification_code
                });
                const orders = this.state.orders;
                orders.splice(orders.indexOf(order), 1);
                this.setState({
                    orders: orders
                }, this.searchOrders)
            } catch (e) {
                console.log(e);
                alert("Operacija nije uspela")
            }
            this.unmarkAsLoading()
        }
    };

    render() {
        return (
            <div>
                {this.state.loading && <Spinner animation="border" variant="primary" size="lg"
                                                style={{
                                                    height: "150px",
                                                    width: "150px",
                                                    align: "center",
                                                    marginLeft: "auto",
                                                    marginRight: "auto",
                                                    display: "block"
                                                }}/>}
                {!this.state.loading && <React.Fragment>
                    <FormGroup>
                        <Form.Label className="mt-2">Pretraga porudžbina po adresi :</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon={faSearch}/>
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control value={this.state.search} placeholder="Unesite adresu"
                                          onChange={this.updateSearchTerm} autoFocus>
                            </Form.Control>
                        </InputGroup>
                    </FormGroup>
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Adresa</th>
                            <th>Vreme</th>
                            <th>Telefon</th>
                            <th>Cena</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.filteredOrders.map((order, index) =>
                            <Order key={index}
                                   order={order}
                                   openOrderId={this.state.openOrderId}
                                   openOrder={this.openOrder(order)}
                                   startLoad={this.markAsLoading}
                                   endLoad={this.unmarkAsLoading}
                                   verifyOrder={this.verifyOrder(order)}
                            />
                        )}
                        </tbody>
                    </Table>
                    <LoadingModal show={this.state.loadingModalOpen}/>
                </React.Fragment>
                }
            </div>
        )
    }

}

NonVerifiedOrdersTab.contextType = AuthContext;

export default NonVerifiedOrdersTab;
