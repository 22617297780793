import React, {Component} from 'react';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import OrdersTab from "./OrdersTab";
import Button from 'react-bootstrap/Button'
import {AuthContext} from "../Contexts";
import ArticlesTab from "./ArticlesTab";
import NonVerifiedOrdersTab from "./NonVerifiedOrdersTab";

class StorePortal extends Component {

    render() {
        return (
            <React.Fragment>
                <Tabs defaultActiveKey="orders" id="uncontrolled-tab-example">
                    <Tab eventKey="orders" title="Porudžbine">
                        <OrdersTab/>
                    </Tab>
                    <Tab eventKey="articles" title="Artikli">
                        <ArticlesTab/>
                    </Tab>
                    <Tab eventKey="nonVerified" title="Nepotvrđene porudžbine">
                        <NonVerifiedOrdersTab/>
                    </Tab>
                </Tabs>
                <Button onClick={this.context.logout}>Odjavi se</Button>
            </React.Fragment>
        )
    }
}

StorePortal.contextType = AuthContext;
export default StorePortal;
