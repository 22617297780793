import React, {Component} from 'react';
import Table from 'react-bootstrap/Table'

class DeliveryPricelist extends Component {
    render() {
        return (
            <div className="container">
                <h4>Koliko to košta?</h4>
                <p>

                    Za svaki artikal se naplaćuje ista cena koju biste platili da ste lično otišli do prodavnice.
                    Dostava se dodatno naplaćuje i njena cena zavisi od oblasti gde se dostava vrši. Cene su iznete
                    u tabeli.
                </p>
                <br/>
                <Table responsive>
                    <thead>
                    <tr>
                        <th>DESTINACIJA</th>
                        <th>CENA DNEVNE VOŽNJE</th>
                        <th>CENA NOĆNE VOŽNJE</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            Adice do Veterničke
                        </td>
                        <td>430</td>
                        <td>480</td>
                    </tr>
                    <tr>
                        <td>
                            Adice od Veterničke
                        </td>
                        <td>500</td>
                        <td> 550
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Avijacija
                        </td>
                        <td>390</td>
                        <td>450
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Begeč
                        </td>
                        <td> 2500</td>
                        <td>3000
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Bocke
                        </td>
                        <td> 850</td>
                        <td>900
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Budisava
                        </td>
                        <td> 2500</td>
                        <td> 3000
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Bukovac
                        </td>
                        <td> 1100</td>
                        <td>1200
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Čenej
                        </td>
                        <td> 1100</td>
                        <td>1200
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Centar stari grad
                        </td>
                        <td> 350</td>
                        <td>400
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Futog
                        </td>
                        <td> 1100</td>
                        <td>1200
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Industrijska zona
                        </td>
                        <td>450</td>
                        <td> 500
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Kać
                        </td>
                        <td> 1500</td>
                        <td>2000
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Kamenjar
                        </td>
                        <td>430</td>
                        <td>480
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Kisač
                        </td>
                        <td>2500</td>
                        <td> 3000
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Klisa
                        </td>
                        <td> 570</td>
                        <td>620
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Kovilj
                        </td>
                        <td> 3000</td>
                        <td>3500
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Ledinci
                        </td>
                        <td> 1300</td>
                        <td>1500
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Paragovo
                        </td>
                        <td> 800</td>
                        <td> 900
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Petrovaradin
                        </td>
                        <td>500</td>
                        <td> 550
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Petrovaradin 2
                        </td>
                        <td> 570</td>
                        <td>620
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Popovica
                        </td>
                        <td> 850</td>
                        <td>950
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Puckaroš
                        </td>
                        <td> 900</td>
                        <td> 1000
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Ribarsko ostrvo
                        </td>
                        <td>450</td>
                        <td>500
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Rimski šančevi
                        </td>
                        <td>750</td>
                        <td>800
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Rumenka
                        </td>
                        <td> 1100</td>
                        <td> 1200
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Sajlovo
                        </td>
                        <td> 570</td>
                        <td>630
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Šangaj
                        </td>
                        <td>800</td>
                        <td> 850
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Slana bara
                        </td>
                        <td> 500</td>
                        <td> 550
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Sombroska Rampa
                        </td>
                        <td>400</td>
                        <td>450
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Sremska Kamenica
                        </td>
                        <td> 500</td>
                        <td>550
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Sremska kamenica 2
                        </td>
                        <td> 570</td>
                        <td>630
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Stepanovicevo
                        </td>
                        <td> 2000</td>
                        <td> 2500
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Veternik
                        </td>
                        <td>500</td>
                        <td>550
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Veternik 2
                        </td>
                        <td> 570</td>
                        <td>630
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Temerin
                        </td>
                        <td>3000</td>
                        <td>3500
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Bački Jarak
                        </td>
                        <td> 2500</td>
                        <td> 3000
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Čardak
                        </td>
                        <td>650</td>
                        <td>700
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Bangladeš
                        </td>
                        <td>750</td>
                        <td>850
                        </td>
                    </tr>
                    </tbody>
                </Table>
                <p>
                    Pod noćnom vožnjom podrazumeva se vožnja u periodu od 00h - 08h.
                </p>
                <p>
                    Na cenu naručene robe i dostave se dodaje i cena kesa u koje je porudžbina spakovana. Svaka kesa
                    se naplaćuje 8 dinara. Tačan broj kesa koje će biti iskorišćene se ne može znati unapred.
                </p>
            </div>
        )
    }
}

export default DeliveryPricelist;
