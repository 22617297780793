import React, {Component} from 'react';
import {AuthContext} from "../Contexts";
import Spinner from "react-bootstrap/Spinner";
import {Form, FormGroup, InputGroup, Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {API_URL} from "../Constants";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import EditArticleForm from "./EditArticleForm";

class ArticlesTab extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            articles: [],
            loading: true,
            search: "",
            openArticleId: null,
            loadingModalOpen: false,
            showOnlyNonCategorized: 'no',
            showOnlyDisplayed: 'no'
        };
        this.loadArticles();
    }

    updateSearchTerm = (event) => {
        this.setState({
            search: event.target.value,
        });
    };

    searchArticles = () => {
        this.setState({
            loading: true,
            openArticleId: null
        });
        this.loadArticles();
    };

    openLoadingModal = () => {
        this.setState({
            loadingModalOpen: true
        })
    };

    closeLoadingModal = () => {
        this.setState({
            loadingModalOpen: false
        })
    };

    loadArticles = () => {
        axios({
            method: 'get',
            url: API_URL + 'searcharticlesupc',
            params: {
                'search': this.state.search,
                'onlydisplayed': this.state.showOnlyDisplayed,
                'onlynoncat': this.state.showOnlyNonCategorized
            },
            headers: {
                'Authorization': this.context.getAuthString()
            }
        })
            .then(response => {
                this.setState({
                    articles: response.data,
                    loading: false
                });
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    articles: [],
                    loading: false
                });
            })
    };

    onKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.searchArticles();
        }
    };

    openArticle = (articleId) => {

        let self = this;
        return function () {
            if (articleId === self.state.openArticleId) {
                self.setState({
                    openArticleId: null
                })
            } else {
                self.setState({
                    openArticleId: articleId
                })
            }
        }
    };

    formatPrice = (price) => {
        return price.toLocaleString("sr-RS", {style: "currency", currency: "RSD", minimumFractionDigits: 2})
    };

    updateArticleState = (newArticle) => {
        let articles = this.state.articles;
        for (const article of articles) {
            if (article.id === newArticle.id) {
                article.name = newArticle.name;
                article.display = newArticle.display;
                article.old_price = newArticle.old_price;
                article.img = newArticle.img;
                article.product_category = newArticle.product_category;
                article.product_group = newArticle.product_group;
                article.product_subgroup = newArticle.product_subgroup;
            }
        }
        this.setState({
            articles: articles
        })
    };

    updateOnlyDisplayed = (event) => {
        this.setState({
            showOnlyDisplayed: event.target.value
        })
    };

    updateOnlyNonCategorized = (event) => {
        this.setState({
            showOnlyNonCategorized: event.target.value
        })
    };


    render() {
        return (
            <div>
                {this.state.loading && <Spinner animation="border" variant="primary" size="lg"
                                                style={{
                                                    height: "150px",
                                                    width: "150px",
                                                    align: "center",
                                                    marginLeft: "auto",
                                                    marginRight: "auto",
                                                    display: "block"
                                                }}/>}
                {!this.state.loading && <React.Fragment>
                    <FormGroup>
                        <Form.Label>Samo artikli prikazani kupcima:</Form.Label>
                        <Form.Control as="select" value={this.state.showOnlyDisplayed}
                                      onChange={this.updateOnlyDisplayed}>
                            <option value={"yes"}>Da</option>
                            <option value={"no"}>Ne</option>
                        </Form.Control>
                    </FormGroup>
                    <FormGroup>
                        <Form.Label>Samo nekategorisani artikli:</Form.Label>
                        <Form.Control as="select" value={this.state.showOnlyNonCategorized}
                                      onChange={this.updateOnlyNonCategorized}>
                            <option value={"yes"}>Da</option>
                            <option value={"no"}>Ne</option>
                        </Form.Control>
                    </FormGroup>
                    <FormGroup>
                        <Form.Label className="mt-2">Pretraga artikala po šifri ili nazivu :</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon={faSearch}/>
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control value={this.state.search} placeholder="Unesite šifru ili naziv"
                                          onChange={this.updateSearchTerm} onKeyPress={this.onKeyPress} autoFocus>
                            </Form.Control>
                            <InputGroup.Prepend>
                                <Button onClick={this.searchArticles}>Pretraži</Button>
                            </InputGroup.Prepend>
                        </InputGroup>
                    </FormGroup>
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Naziv</th>
                            <th>Barkod</th>
                            <th>Lager</th>
                            <th>Cena</th>
                            <th>Prikazan</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.articles.map((article, index) =>
                            <React.Fragment key={index}>
                                <tr className={article.id === this.state.openArticleId ? "table-info" : ""}
                                    onClick={this.openArticle(article.id)}>
                                    <td>{article.id}</td>
                                    <td>{article.name}</td>
                                    <td>{article.universal_product_code}</td>
                                    <td>{article.stock}</td>
                                    <td>{this.formatPrice(article.price)}</td>
                                    <td>{article.display ? "Da" : "Ne"}</td>
                                </tr>
                                {this.state.openArticleId === article.id &&
                                <tr className={article.id === this.state.openArticleId ? "table-info" : ""}>
                                    <td colSpan={6}>
                                        <Card>
                                            <Card.Body>
                                                <EditArticleForm article={article}
                                                                 openLoadingModal={this.openLoadingModal}
                                                                 closeLoadingModal={this.closeLoadingModal}
                                                                 updateArticleState={this.updateArticleState}/>
                                            </Card.Body>
                                        </Card>
                                    </td>
                                </tr>}
                            </React.Fragment>
                        )}
                        </tbody>
                    </Table>
                    <Modal show={this.state.loadingModalOpen}>
                        <Modal.Header>
                            <Modal.Title>Molimo sačekajte</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Spinner animation="border" variant="primary" size="lg"
                                     style={{
                                         height: "150px",
                                         width: "150px",
                                         align: "center",
                                         marginLeft: "auto",
                                         marginRight: "auto",
                                         display: "block"
                                     }}/>
                        </Modal.Body>
                    </Modal>
                </React.Fragment>
                }
            </div>
        )
    }
}

ArticlesTab.contextType = AuthContext;

export default ArticlesTab;