import React, {Component} from 'react';
import {AuthContext} from "../Contexts";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form';
import FormGroup from "react-bootstrap/FormGroup";
import Button from "react-bootstrap/Button";
import axios from 'axios'
import {API_URL} from '../Constants'

class EditArticleForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            uploadedFile: null,
            previewSource: props.article.img,
            imageChanged: false,
            uploadedPromotionFile: null,
            promotionPreviewSource: props.article.promotion_img,
            promotionImageChanged: false,
            removePromotionImage: false,
            name: props.article.name,
            sortingPriority: props.article.sorting_priority,
            valuesChanged: false,
            display: props.article.display,
            oldPrice: props.article.old_price ? props.article.old_price : "",
            productCategory: props.article.product_category ? props.article.product_category : "",
            productGroup: props.article.product_group ? props.article.product_group : "",
            productSubgroup: props.article.product_subgroup ? props.article.product_subgroup : "",
            promotionDescription: props.article.promotion_description ? props.article.promotion_description : "",
            categories: [],
            groups: [],
            subgroups: []
        };
        this.fileInput = React.createRef();
        this.promotionFileInput = React.createRef();
        this.fetchCategories();
        this.fetchGroups();
        this.fetchSubgroups();
    }

    onFileChange = (event) => {
        this.setState({
            uploadedFile: event.target.files[0],
            previewSource: URL.createObjectURL(event.target.files[0]),
            valuesChanged: true,
            imageChanged: true
        })
    };

    onPromotionFileChange = (event) => {
        this.setState({
            uploadedPromotionFile: event.target.files[0],
            promotionPreviewSource: URL.createObjectURL(event.target.files[0]),
            valuesChanged: true,
            promotionImageChanged: true
        })
    };

    removePromotionImage = () => {
        this.setState({
            removePromotionImage: true,
            promotionPreviewSource: "",
            valuesChanged: true
        })
    }

    fetchCategories = () => {
        const self = this;
        axios.get(API_URL + 'categories').then(function (response) {
            self.setState({
                categories: response.data
            })
        })
    };

    updateCategory = (event) => {
        let newValue = event.target.value;
        if (this.state.productCategory !== newValue) {
            this.setState({
                productCategory: newValue,
                productGroup: "",
                productSubgroup: "",
                groups: [],
                subgroups: [],
                valuesChanged: true
            }, this.fetchGroups);

        }
    };

    fetchGroups = () => {
        const self = this;
        if (this.state.productCategory) {
            axios.get(API_URL + 'categories/' + this.state.productCategory).then(function (response) {
                self.setState({
                    groups: response.data
                })
            })
        }
    };

    updateGroup = (event) => {
        const newValue = event.target.value;
        if (this.state.productGroup !== newValue) {
            this.setState({
                productGroup: newValue,
                productSubgroup: "",
                valuesChanged: true
            }, this.fetchSubgroups);
        }
    };

    fetchSubgroups = () => {
        const self = this;
        if (this.state.productGroup) {
            axios.get(API_URL + 'categories/' + this.state.productCategory + "/" + this.state.productGroup).then(function (response) {
                self.setState({
                    subgroups: response.data
                })
            })
        }
    };

    updateSubgroup = (event) => {
        const newValue = event.target.value;
        if (this.state.productSubgroup !== newValue) {
            this.setState({
                productSubgroup: newValue,
                valuesChanged: true
            });
        }
    };

    updateName = (event) => {
        this.setState({
            name: event.target.value,
            valuesChanged: true
        })
    };

    updatePromotionDescription = (event) => {
        this.setState({
            promotionDescription: event.target.value,
            valuesChanged: true
        })
    };

    updateSortingPriority = (event) => {
        this.setState({
            sortingPriority: event.target.value,
            valuesChanged: true
        })
    };

    updateDisplay = (event) => {
        this.setState({
            display: event.target.checked,
            valuesChanged: true
        })
    };

    updateArticle = () => {
        this.props.openLoadingModal();
        let self = this;
        let formData = new FormData();
        formData.append('upc', this.props.article.universal_product_code);
        formData.append('image', this.state.uploadedFile);
        formData.append('imageChanged', this.state.imageChanged);
        formData.append('promotionImage', this.state.uploadedPromotionFile);
        formData.append('promotionImageChanged', this.state.promotionImageChanged);
        formData.append('removePromotionImage', this.state.removePromotionImage);
        formData.append('name', this.state.name);
        formData.append('sortingPriority', this.state.sortingPriority);
        formData.append('display', this.state.display);
        formData.append('oldPrice', this.state.oldPrice);
        formData.append('productCategoryId', this.state.productCategory);
        formData.append('productGroupId', this.state.productGroup);
        formData.append('productSubgroupId', this.state.productSubgroup);
        formData.append('promotionDescription', this.state.promotionDescription);

        axios.post(API_URL + 'articles/updatearticle', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': this.context.getAuthString()
                }
            }
        ).then(function (response) {
            self.props.updateArticleState(response.data);
            self.props.closeLoadingModal();
        })
            .catch(error => {
                alert("Akcija nije uspela");
                console.log(error);
                self.props.closeLoadingModal();
            });
    };

    formatPrice = (price) => {
        return price.toLocaleString("sr-RS", {style: "currency", currency: "RSD", minimumFractionDigits: 2})
    };

    updateOldPrice = (event) => {
        let num = event.target.value;
        if (num.toString().split(".").length < 2 || num.toString().split(".")[1].length <= 2)
            this.setState({
                oldPrice: event.target.value,
                valuesChanged: true
            })
    };

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <Row>

                    <Col xl={3} lg={3} md={3} sm={3} xs={3}>
                        <img src={this.state.previewSource ? this.state.previewSource : 'images/no-camera.png'} alt={""}
                             height="150" width="150"/>
                        <br/>
                        <FormGroup>
                            <Form.Label>Promeni Sliku:</Form.Label>
                            <Form.Control type="file" ref={this.fileInput} onChange={this.onFileChange}/>
                        </FormGroup>
                        <img
                            src={this.state.promotionPreviewSource ? this.state.promotionPreviewSource : 'images/no-camera.png'}
                            alt={""}
                            height="150" width="150"/>
                        <br/>
                        <FormGroup>
                            <Form.Label>Slika Promocije:</Form.Label>
                            <Form.Control type="file" ref={this.promotionFileInput}
                                          onChange={this.onPromotionFileChange}/>
                        </FormGroup>
                        <Button disabled={!this.state.promotionPreviewSource} onClick={this.removePromotionImage}>Izbriši
                            sliku promocije</Button>
                    </Col>

                    <Col xl={9} lg={9} md={9} sm={9} xs={9}>
                        <FormGroup>
                            <Form.Label>Barkod</Form.Label>
                            <Form.Control type="text" disabled value={this.props.article.universal_product_code}/>
                        </FormGroup>
                        <FormGroup>
                            <Form.Label>Naziv</Form.Label>
                            <Form.Control type="text" value={this.state.name}
                                          onChange={this.updateName}/>
                        </FormGroup>
                        <FormGroup>
                            <Form.Label>Prioritet u sortiranju</Form.Label>
                            <Form.Control type="number" value={this.state.sortingPriority}
                                          onChange={this.updateSortingPriority}/>
                        </FormGroup>
                        <FormGroup>
                            <Form.Label>Cena</Form.Label>
                            <Form.Control disabled type="text" value={this.formatPrice(this.props.article.price)}/>
                        </FormGroup>
                        <FormGroup>
                            <Form.Label>Stara cena</Form.Label>
                            <Form.Control type="number" onChange={this.updateOldPrice} placeholder="0.00"
                                          value={this.state.oldPrice}/>
                        </FormGroup>
                        <FormGroup>
                            <Form.Label>Na lageru</Form.Label>
                            <Form.Control type="text" disabled value={this.props.article.stock}/>
                        </FormGroup>
                        <FormGroup>
                            <Form.Label>Kategorija</Form.Label>
                            <Form.Control as="select" value={this.state.productCategory} onChange={this.updateCategory}>
                                {this.state.categories.map((category, index) => {
                                    return (
                                        <option key={"category" + index} value={category.id}>{category.name}</option>
                                    )
                                })}
                                <option value={""}>Nije Uneto</option>
                            </Form.Control>
                        </FormGroup>
                        <FormGroup>
                            <Form.Label>Grupa</Form.Label>
                            <Form.Control as="select" value={this.state.productGroup}
                                          disabled={!this.state.productCategory} onChange={this.updateGroup}>
                                {this.state.groups.map((group, index) => {
                                    return (
                                        <option key={"group" + index} value={group.id}>{group.name}</option>
                                    )
                                })}
                                <option value={""}>Nije Uneto</option>
                            </Form.Control>
                        </FormGroup>
                        <FormGroup>
                            <Form.Label>Podgrupa</Form.Label>
                            <Form.Control as="select" value={this.state.productSubgroup}
                                          disabled={!this.state.productGroup} onChange={this.updateSubgroup}>
                                {this.state.subgroups.map((subgroup, index) => {
                                    return (
                                        <option key={"subgroup" + index} value={subgroup.id}>{subgroup.name}</option>
                                    )
                                })}
                                <option value={""}>Nije Uneto</option>
                            </Form.Control>
                        </FormGroup>
                        <FormGroup>
                            <Form.Label>Opis promocije</Form.Label>
                            <Form.Control type="text" value={this.state.promotionDescription}
                                          onChange={this.updatePromotionDescription}/>
                        </FormGroup>
                        <FormGroup>
                            <Form.Check type='checkbox' checked={this.state.display} label="Prikaži kupcima"
                                        onChange={this.updateDisplay}/>
                        </FormGroup>
                        <Button disabled={!this.state.valuesChanged} onClick={this.updateArticle}>Sačuvaj
                            izmene</Button>
                    </Col>
                </Row>
            </form>
        )
    }
}

EditArticleForm.contextType = AuthContext;

export default EditArticleForm;
