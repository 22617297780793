import React, {Component} from 'react'
import {API_URL, ORDER_LIMITS} from '../Constants'
import Alert from "react-bootstrap/Alert";
import axios from "axios";

class OrderLimitNotice extends Component {

    constructor(props) {
        super(props);
        this.state={
            numberOfOrders:0
        }
        this.fetchNumberOfOrders();
    }

    fetchNumberOfOrders = () => {
        axios({
            method: 'get',
            url: API_URL + 'ordersnumber',
            config: {headers: {"Access-Control-Allow-Origin": "*"}},
            withCredentials: true
        })
            .then(response => {
                this.setState({
                    numberOfOrders: response.data,
                });
            });
    }

    render() {
        if (this.state.numberOfOrders >= ORDER_LIMITS.disallowOrder) {
            return (<div>
                    <Alert variant="danger">
                        Zbog prebukiranosti kapaciteta trenutno nismo u mogućnosti da primimo vašu narudžbinu. Molimo
                        pokušajte kasnije.
                    </Alert>
                </div>
            )
        } else if (this.state.numberOfOrders >= ORDER_LIMITS.moreThan120Minutes) {
            return (<div>
                    <Alert variant="warning">
                        Zbog prebukiranosti kapaciteta trenutno nismo u mogućnosti da dostavimo vašu porudžbinu u
                        predviđenom roku.
                        Ukoliko ste saglasni da se vaša porudžbina dostavi za duže od 120 minuta nastavite sa
                        kupovinom.
                    </Alert>
                </div>
            )
        } else if (this.state.numberOfOrders >= ORDER_LIMITS.moreThan90Minutes) {
            return (<div>
                    <Alert variant="warning">
                        Zbog prebukiranosti kapaciteta trenutno nismo u mogućnosti da dostavimo vašu porudžbinu u
                        predviđenom roku.
                        Ukoliko ste saglasni da se vaša porudžbina dostavi za duže od 90 minuta nastavite sa
                        kupovinom.
                    </Alert>
                </div>
            )
        } else if (this.state.numberOfOrders >= ORDER_LIMITS.moreThan60minutes) {
            return (<div>
                    <Alert variant="warning">
                        Zbog prebukiranosti kapaciteta trenutno nismo u mogućnosti da dostavimo vašu porudžbinu u
                        predviđenom roku.
                        Ukoliko ste saglasni da se vaša porudžbina dostavi za duže od 60 minuta nastavite sa
                        kupovinom.
                    </Alert>
                </div>
            )
        } else return null
    }

}

export default OrderLimitNotice;
