import React, {Component} from 'react';
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import {CartContext} from '../../Contexts'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-bootstrap/Modal'
import loadAnimation from '../../91.svg'

class ArticleCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            amount: 1,
            showDialog: false,
            loaded: false,
            showPromotionDialog: false
        };
    }

    handleAmountChange = (event) => {
        let newAmount = parseFloat(event.target.value);
        let leftAvailable = this.props.article.stock - this.amountInCart();
        if (newAmount > leftAvailable) {
            newAmount = leftAvailable;
            this.openDialog()
        }
        this.setState({
            amount: newAmount
        });
    };

    formatPrice = (price) => {
        return price.toLocaleString("sr-RS", {style: "currency", currency: "RSD", minimumFractionDigits: 2})
    };

    addToCart = () => {
        // alert("Nepravilno unesena kolicina")
        let amount = this.state.amount;
        let leftAvailable = this.props.article.stock - this.amountInCart();
        if (amount <= leftAvailable) {
            this.addAmountToCart(amount)
        } else {
            this.openDialog()
        }
    };

    removeFromCart = () => {
        // alert("Nepravilno unesena kolicina")
        let amount = -this.state.amount;
        this.addAmountToCart(amount)
    };

    addAmountToCart = (amount) => {
        let multiplier = 1 / this.props.article.amount_step;
        let amountToAdd = Math.round(amount * multiplier) / multiplier;
        this.context.addArticle(this.props.article.id, amountToAdd, this.props.article);
        this.setState({
            amount: this.props.article.amount_step
        });
    };

    amountInCart = () => {
        return this.context.articles[this.props.article.id] ? this.context.articles[this.props.article.id].amount : 0;
    };

    shouldDisplayDiscount = () => {
        return this.props.article.old_price && parseFloat(this.props.article.price) < (parseFloat(this.props.article.old_price) * 0.99)
    };

    closeDialog = () => {
        this.setState({
            showDialog: false
        })
    };

    closePromotionDialog = () => {
        this.setState({
            showPromotionDialog: false
        })
    };

    openDialog = () => {
        this.setState({
            showDialog: true
        })
    };

    openPromotionDialog = () => {
        this.setState({
            showPromotionDialog: true
        })
    };

    onLoad = () => {
        this.setState({
            loaded: true
        });
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.article.id !== this.props.article.id) {
            this.setState({
                loaded: false,
                amount: 1,
            });
        }
    }

    render() {
        return (
            <Card>
                <div style={{
                    width: "150px",
                    height: "150px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    position: "relative"
                }}>
                    <Card.Img variant="top"
                              src={this.props.article.img ? this.props.article.img : 'images/no-camera.png'}
                              onLoad={this.onLoad}
                              style={{
                                  display: this.state.loaded ? "block" : "none",
                                  maxWidth: "150px",
                                  maxHeight: "150px",
                                  width: "auto",
                                  height: "auto",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  verticalAlign: "middle",
                                  position: 'absolute',
                                  top: '50%',
                                  transform: 'translateY(-50%) translateX(-50%)',
                                  zIndex: 0,
                                  left: '50%'
                              }}/>
                    <Card.ImgOverlay>
                        <Card.Img variant="top"
                                  src={loadAnimation}
                                  style={{
                                      display: this.state.loaded ? "none" : "block",
                                      maxWidth: "150px",
                                      maxHeight: "150px",
                                      width: "auto",
                                      height: "auto",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                      verticalAlign: "middle",
                                      position: 'absolute',
                                      top: '50%',
                                      transform: 'translateY(-50%) translateX(-50%)',
                                      zIndex: 0,
                                      left: '50%'
                                  }}/>
                    </Card.ImgOverlay>
                </div>
                <Card.Body>
                    <Card.Title style={{height: '50px', overflow: 'auto'}}>{this.props.article.name}</Card.Title>
                    <Card.Subtitle className="mb-2">
                        {this.shouldDisplayDiscount() && <React.Fragment>
                            <del className="text-danger">  {this.props.article.old_price}</del>
                            {'\u00A0'}
                        </React.Fragment>}
                        {this.formatPrice(this.props.article.price)}/{this.props.article.purchasing_amount_type}
                    </Card.Subtitle>
                    <Card.Text>
                        U Korpi : {this.amountInCart()} {this.props.article.purchasing_amount_type}
                    </Card.Text>
                    <InputGroup>
                        <Form.Control
                            type="number"
                            aria-describedby="inputGroupPrepend"
                            step={this.props.article.amount_step}
                            value={this.state.amount}
                            onChange={this.handleAmountChange}
                        />
                        <InputGroup.Prepend>
                            <InputGroup.Text>{this.props.article.purchasing_amount_type}</InputGroup.Text>
                        </InputGroup.Prepend>
                        <InputGroup.Prepend>
                            <Button onClick={this.addToCart}>Dodaj</Button>
                            {/*<Button onClick={this.openDialog}>Dodaj</Button>*/}
                        </InputGroup.Prepend>
                        <InputGroup.Prepend>
                            <Button variant="outline-primary" onClick={this.removeFromCart}>-</Button>
                        </InputGroup.Prepend>
                    </InputGroup>
                    <Modal show={this.state.showDialog} onHide={this.closeDialog}>
                        <Modal.Header closeButton>
                            <Modal.Title>Nedovoljna količina na lageru</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Ovog artikla je na lageru
                                dostupno {this.props.article.stock} {this.props.article.purchasing_amount_type} </p>
                        </Modal.Body>
                    </Modal>
                    <Modal show={this.state.showDialog} onHide={this.closeDialog}>
                        <Modal.Header closeButton>
                            <Modal.Title>Nedovoljna količina na lageru</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Ovog artikla je na lageru
                                dostupno {this.props.article.stock} {this.props.article.purchasing_amount_type} </p>
                        </Modal.Body>
                        {/*<Modal.Header closeButton>*/}
                        {/*    <Modal.Title>VAŽNO OBAVEŠTENJE</Modal.Title>*/}
                        {/*</Modal.Header>*/}
                        {/*<Modal.Body>*/}
                        {/*    <p>Poštovani kupci,trenutno nije moguće*/}
                        {/*        poručiti porudžbinu zbog velike gužve.Molimo Vas da pokušate kasnije.Izvinjavamo se na*/}
                        {/*        neprijatnosti.</p>*/}
                        {/*    <p>Vaš Mikromarket.</p>*/}
                        {/*</Modal.Body>*/}
                    </Modal>
                </Card.Body>
            </Card>
        );
    }
}

ArticleCard.contextType = CartContext;
export default ArticleCard;
