import React, {Component} from 'react'
import Modal from "react-bootstrap/Modal";

class InfoPopup extends Component {
    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>VAŽNO OBAVEŠTENJE</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>Poštovani kupci,trenutno nije moguće
                        poručiti porudžbinu zbog velike gužve.Molimo Vas da pokušate kasnije.Izvinjavamo se na
                        neprijatnosti.</p>
                    <p>Vaš Mikromarket.</p>
                </Modal.Body>
            </Modal>
        )
    }
}

export default InfoPopup;
