import React, {Component} from 'react';
import {withRouter} from 'react-router-dom'
import Button from 'react-bootstrap/Button'

class CheckoutRedirectButton extends Component {

    nextPath = (path) => {
        this.props.history.push(path);
    };

    render() {
        return (
            <Button onClick={() => this.nextPath("/checkout")} variant="light">Na kasu</Button>
        )
    }
}

export default withRouter(CheckoutRedirectButton);