import React, {Component} from 'react';
import {Modal} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

class LoadingModal extends Component {
    render() {
        return (
            <Modal show={this.props.show}>
                <Modal.Header>
                    <Modal.Title>Molimo sačekajte</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Spinner animation="border" variant="primary" size="lg"
                             style={{
                                 height: "150px",
                                 width: "150px",
                                 align: "center",
                                 marginLeft: "auto",
                                 marginRight: "auto",
                                 display: "block"
                             }}/>
                </Modal.Body>

            </Modal>
        )
    }
}

export default LoadingModal;