import React, {Component} from 'react';
import {OrdersContext} from "../Contexts";
import FormGroup from "react-bootstrap/FormGroup";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Table from 'react-bootstrap/Table'
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import {MISSING_ARTICLE_ACTIONS} from "../Constants";

class OrdersPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            phoneNumber: "",
            verificationCode: ""
        };
    }

    updatePhoneNumber = (event) => {
        this.setState({
            phoneNumber: event.target.value
        });
    };

    updateVerificationCode = (event) => {
        this.setState({
            verificationCode: event.target.value
        });
    };

    fetchOrder = () => {
        this.context.fetchOrder(this.state.phoneNumber, this.state.verificationCode);
    };

    formatTime = (timestring) => {
        // return new Date(timestring).toLocaleString('en-GB', {timeZone: 'UTC'})
        return new Date(timestring).toLocaleString('sr-RS')
    };

    formatPrice = (price) => {
        return price.toLocaleString("sr-RS", {style: "currency", currency: "RSD", minimumFractionDigits: 2})
    };

    calculateTotalCost = () => {
        let total = 0;
        this.context.order.orderlines.forEach((orderline) => {
            if (!orderline.canceled)
                total += orderline.article.price * orderline.quantity
        });
        return this.formatPrice(total)
    };

    render() {
        return (
            <React.Fragment>
                {
                    this.context.order.verification_code && !this.context.loading && <React.Fragment>
                        <div>
                            <Table responsive>
                                <thead>
                                <tr>
                                    <th>Naziv</th>
                                    <th>Količina</th>
                                    <th>Jedinica mere</th>
                                    <th>Cena po j.m.</th>
                                    <th>Cena</th>
                                    <th/>
                                </tr>
                                </thead>
                                <tbody>
                                {this.context.order.orderlines.map((orderline, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <tr className={orderline.canceled ? "table-danger" : orderline.subsequent_addition ? "table-active" : ""}>
                                                <td data-title="Naziv">{orderline.article.name}</td>
                                                <td data-title="Količina">{orderline.quantity}</td>
                                                <td data-title="Jedinica Mere">{orderline.article.purchasing_amount_type}</td>
                                                <td data-title="Cena po j.m.">{this.formatPrice(orderline.article.price)}</td>

                                                <td data-title="Cena">
                                                    {orderline.canceled &&
                                                    <del>{this.formatPrice(orderline.article.price *
                                                        orderline.quantity)}</del>}
                                                    {!orderline.canceled && this.formatPrice(orderline.article.price * orderline.quantity)}
                                                </td>
                                            </tr>
                                            {orderline.article.promotion_description &&
                                            <tr>
                                                <td colSpan={5}>
                                                    <p>{orderline.article.promotion_description}</p>
                                                </td>
                                            </tr>}
                                        </React.Fragment>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </div>
                        <dl className="row">
                            <dt className="col-sm-12"><strong className="float-right">Ukupna cena</strong></dt>
                            <dd className="col-sm-12"><strong
                                className="float-right">{this.calculateTotalCost()} + dostava i kese</strong></dd>

                            <dt className="col-sm-3">Ime naručioca</dt>
                            <dd className="col-sm-9">{this.context.order.customer_name}</dd>

                            <dt className="col-sm-3">Broj telefona</dt>
                            <dd className="col-sm-9">{this.context.order.phone_number}</dd>

                            <dt className="col-sm-3">Email</dt>
                            <dd className="col-sm-9">{this.context.order.email}</dd>

                            {this.context.order.is_pickup && <React.Fragment>
                                <dt className="col-sm-3">Mesto preuzimanja</dt>
                                <dd className="col-sm-9">Mikromarket, Geri Karolja 33</dd>
                            </React.Fragment>}

                            {!this.context.order.is_pickup && <React.Fragment>

                                <dt className="col-sm-3">Deo grada</dt>
                                <dd className="col-sm-9">{this.context.order.neighbourhood}</dd>

                                <dt className="col-sm-3">Ulica</dt>
                                <dd className="col-sm-9">{this.context.order.street}</dd>

                                <dt className="col-sm-3">Broj</dt>
                                <dd className="col-sm-9">{this.context.order.street_number}</dd>

                                <dt className="col-sm-3">Sprat</dt>
                                <dd className="col-sm-9">{this.context.order.floor}</dd>

                                <dt className="col-sm-3">Broj stana</dt>
                                <dd className="col-sm-9">{this.context.order.apartment_number}</dd>

                                <dt className="col-sm-3">Natpis na interfonu</dt>
                                <dd className="col-sm-9">{this.context.order.intercom_label}</dd>

                            </React.Fragment>}

                            <dt className="col-sm-3">Napomena</dt>
                            <dd className="col-sm-9">{this.context.order.note}</dd>

                            <dt className="col-sm-3">Vreme poručivanja</dt>
                            <dd className="col-sm-9">{this.formatTime(this.context.order.verified)}</dd>

                            <dt className="col-sm-3">Spakovano</dt>
                            {this.context.order.fulfilled &&
                            <dd className="col-sm-9"> {this.formatTime(this.context.order.fulfilled)} </dd>}
                            {!this.context.order.fulfilled && <dd className="col-sm-9"> Ne </dd>}
                        </dl>
                        {this.context.order.missing_article_action && this.context.order.missing_article_action &&
                        <React.Fragment>
                            <strong>Ako nekog od naručenih artikala ne bude na stanju:</strong>
                            {this.context.order.missing_article_action === MISSING_ARTICLE_ACTIONS.IGNORE &&
                            <p>Biće vam isporučen ostatak narudžbine bez tog artikla.</p>
                            }
                            {this.context.order.missing_article_action === MISSING_ARTICLE_ACTIONS.CANCEL &&
                            <p>Vaša narudžbina će biti poništena.</p>
                            }
                            {this.context.order.missing_article_action === MISSING_ARTICLE_ACTIONS.REPLACE &&
                            <p>
                                Artikal će biti zamenjen istim druge gramaže ili sličnim artiklom drugog
                                proizvođača.
                            </p>
                            }
                        </React.Fragment>
                        }
                        <h5>Pogledajte drugu porudžbinu</h5>
                    </React.Fragment>
                }
                {this.context.loading && <Spinner animation="border" variant="primary" size="lg"
                                                  style={{
                                                      height: "150px",
                                                      width: "150px",
                                                      align: "center",
                                                      marginLeft: "auto",
                                                      marginRight: "auto",
                                                      display: "block"
                                                  }}/>}
                {!this.context.order.verification_code && <h5>Pogledajte porudžbinu</h5>}
                <FormGroup>
                    <Form.Label>Broj telefona ili email</Form.Label>
                    <InputGroup>
                        <Form.Control type="text" value={this.state.phoneNumber} isInvalid={this.context.errorOnFetch}
                                      onChange={this.updatePhoneNumber}/>
                    </InputGroup>
                </FormGroup>
                <FormGroup>
                    <Form.Label>Verifikacioni kod</Form.Label>
                    <Form.Control type="text" value={this.state.verificationCode} isInvalid={this.context.errorOnFetch}
                                  onChange={this.updateVerificationCode}/>
                </FormGroup>
                {this.context.errorOnFetch &&
                <h6 className="text-danger">Takva narudžbina nije pronađena u spisku potvrđenih narudžbina.</h6>}
                <Button onClick={this.fetchOrder}>Pogledajte porudžbinu</Button>
                <Modal show={this.context.confirmModalOpen} onHide={this.context.closeConfirmModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Porudžbina uspešno uneta</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Vaša porudžbina je primljena.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.context.closeConfirmModal}>
                            Zatvori
                        </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        );
    }

}

OrdersPage.contextType = OrdersContext;
export default OrdersPage;
