import React, {Component} from 'react';
import {AuthContext} from '../Contexts'
import Form from 'react-bootstrap/Form'
import Card from "react-bootstrap/Card";
import Button from 'react-bootstrap/Button'
import axios from "axios";
import {API_URL} from "../Constants";
import Spinner from "react-bootstrap/Spinner";
import StorePortal from "./StorePortal";

class AuthenticationScreen extends Component {

    constructor(props) {
        super(props);
        let authCookie = document.cookie.split(';').filter((item) => item.includes('authString'));
        let authString = "";
        if (authCookie.length) {
            authString = authCookie[0].split("=")[1];
        }
        this.state = {
            username: "",
            password: "",
            authString: authString,
            wrongInput: false,
            loading: false,
            rememberMe: false,
            authContext: {
                getAuthString: this.getAuthString,
                logout: this.logout
            }
        }
    }

    getAuthString = () => {
        return this.state.authString;
    };

    login = () => {
        this.setState({
            username: "",
            password: "",
            loading: true
        });
        axios.post(API_URL + 'login/', {
            username: this.state.username,
            password: this.state.password
        })
            .then(response => {
                this.setState({
                    authString: "Token " + response.data.token,
                    wrongInput: false,
                    loading: false
                });
                if (this.state.rememberMe) {
                    document.cookie = "authString=Token " + response.data.token + "; expires=Tue, 19 Jan 2038 03:14:07 UTC;";
                }
            })
            .catch(() => {
                this.setState({
                    authString: "",
                    wrongInput: true,
                    loading: false
                })
            })
    };
    logout = () => {
        this.setState({
            authString: "",
        });
        document.cookie = "authString=Token; expires = Thu, 01 Jan 1970 00:00:00 GMT"
    };

    updateUsername = (event) => {
        this.setState({
            username: event.target.value,
            wrongInput: false
        });
    };

    updatePassword = (event) => {
        this.setState({
            password: event.target.value,
            wrongInput: false
        });
    };

    updateRememberMe = (event) => {
        this.setState({
            rememberMe: event.target.checked
        })
    };

    render() {
        if (this.state.authString) {
            return (
                <AuthContext.Provider value={this.state.authContext}>
                    <StorePortal/>
                </AuthContext.Provider>
            )
        } else {
            if (this.state.loading) {
                return (
                    <Spinner animation="border" variant="primary" size="lg"
                             style={{
                                 height: "150px",
                                 width: "150px",
                                 align: "center",
                                 marginLeft: "auto",
                                 marginRight: "auto",
                                 display: "block"
                             }}/>
                )
            } else {
                return (
                    <React.Fragment>
                        {this.state.loading && <Spinner animation="border" variant="primary" size="lg"
                                                        style={{
                                                            height: "150px",
                                                            width: "150px",
                                                            align: "center",
                                                            marginLeft: "auto",
                                                            marginRight: "auto",
                                                            display: "block"
                                                        }}/>}
                        {!this.state.loading && <Card>
                            <Card.Body>
                                <Form.Group>
                                    <Form.Label>Korisničko ime</Form.Label>
                                    <Form.Control value={this.state.username} onChange={this.updateUsername} autoFocus
                                                  isInvalid={this.state.wrongInput}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Lozinka</Form.Label>
                                    <Form.Control type="password" value={this.state.password}
                                                  onChange={this.updatePassword}
                                                  isInvalid={this.state.wrongInput}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Check type='checkbox' checked={this.state.rememberMe} label="Zapamti me"
                                                onChange={this.updateRememberMe}/>
                                </Form.Group>
                                <Button onClick={this.login}>Prijavite se</Button>
                            </Card.Body>
                        </Card>}
                    </React.Fragment>
                )
            }
        }
    }
}

export default AuthenticationScreen;