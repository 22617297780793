import React, {Component} from 'react'
import Card from "react-bootstrap/Card";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {MISSING_ARTICLE_ACTIONS} from "../../Constants";
import NewOrderlineRow from "./NewOrderlineRow";
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import * as PropTypes from "prop-types";

class Order extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedDeliveryService: "0698300139",
            deliveryServices: [
                {
                    name: "Padrino",
                    phone: "0698300139"
                },
            ]
        }
    }

    formatPrice = (price) => {
        return price.toLocaleString("sr-RS", {style: "currency", currency: "RSD", minimumFractionDigits: 2})
    };

    formatTime = (timestring) => {
        // return new Date(timestring).toLocaleString('en-GB', {timeZone: 'UTC'})
        return new Date(timestring).toLocaleString('sr-RS')
    };

    calculatePrice = () => {
        let price = 0;
        this.props.order.orderlines.forEach(function (orderline) {
            if (!orderline.canceled)
                price += orderline['article']['price'] * orderline['quantity']
        });
        return this.formatPrice(price);
    };

    fulfillOrder = () => {
        this.props.fulfillOrder(this.state.selectedDeliveryService)
    }

    setDeliveryNumber = (number) => {
        return () => {
            this.setState({
                selectedDeliveryService: number
            })
        }
    }

    render() {
        const order = this.props.order;
        return <React.Fragment>
            <tr className={this.props.order.canceled ? "table-danger" : this.props.order.fulfilled ? "table-success" : this.props.order.id === this.props.openOrderId ? "table-info" : ""}
                onClick={this.props.openOrder}>
                <td>{this.props.order.id}</td>
                {!this.props.order.is_pickup &&
                <td>{this.props.order.street} {this.props.order.street_number}</td>}
                {this.props.order.is_pickup &&
                <td>Lično preuzimanje</td>}
                <td>{this.formatTime(this.props.order.verified ? this.props.order.verified : this.props.order.created)}</td>
                <td>{this.props.order.phone_number}</td>
                <td>{this.calculatePrice()}</td>
            </tr>
            {this.props.openOrderId === this.props.order.id &&
            <tr className={this.props.order.canceled ? "table-danger" : this.props.order.fulfilled ? "table-success" : this.props.order.id === this.props.openOrderId ? "table-info" : ""}>
                <td colSpan="6">
                    <Card bg="red">
                        <Card.Body>
                            <Table>
                                <thead>
                                <tr>
                                    <th>Naziv</th>
                                    <th>Količina</th>
                                    <th>j.m</th>
                                    <th>Cena po j.m.</th>
                                    <th>Cena</th>
                                    {this.props.cancelOrderline && <th>Poništi</th>}
                                </tr>
                                </thead>
                                <tbody>
                                {this.props.order.orderlines.map((orderline, orderlineIndex) =>
                                    <React.Fragment key={orderlineIndex}>
                                        <tr className={orderline.canceled ? "table-danger" : orderline.subsequent_addition ? "table-active" : ""}>
                                            <td>{orderline['article']['name']}</td>
                                            <td>{orderline['quantity']}</td>
                                            <td>{orderline['article']['purchasing_amount_type']}</td>
                                            <td>{this.formatPrice(orderline['article']['price'])}</td>
                                            <td>{this.formatPrice(orderline['article']['price'] * orderline['quantity'])}</td>
                                            {this.props.cancelOrderline &&
                                            <td><Button onClick={this.props.cancelOrderline(order, orderline)}
                                                        disabled={orderline['canceled'] || order.canceled || order.fulfilled}
                                                        variant={orderline['canceled'] || order.canceled || order.fulfilled ? "dark" : "danger"}>
                                                <FontAwesomeIcon icon={faTimes}/></Button>
                                            </td>
                                            }
                                        </tr>
                                        {orderline.article.promotion_description ?
                                            <tr>
                                                <td colSpan={6}>
                                                    {orderline.article.promotion_description}
                                                </td>
                                            </tr> : null}
                                    </React.Fragment>
                                )}
                                {this.props.addOrderline ?
                                    <NewOrderlineRow order={this.props.order} onSuccess={this.props.addOrderline}
                                                     startLoad={this.props.startLoad}
                                                     endLoad={this.props.endLoad}/> : null
                                }
                                </tbody>
                            </Table>
                            <dl className="row">
                                <dt className="col-sm-12"><strong className="float-right">Ukupna
                                    cena</strong></dt>
                                <dd className="col-sm-12"><strong
                                    className="float-right">{this.calculatePrice()}</strong>
                                </dd>

                                <dt className="col-sm-3">Ime naručioca</dt>
                                <dd className="col-sm-9">{this.props.order.customer_name}</dd>

                                <dt className="col-sm-3">Email</dt>
                                <dd className="col-sm-9">{this.props.order.email}</dd>

                                <dt className="col-sm-3">Deo grada</dt>
                                <dd className="col-sm-9">{this.props.order.neighbourhood}</dd>

                                <dt className="col-sm-3">Ulica</dt>
                                <dd className="col-sm-9">{this.props.order.street}</dd>

                                <dt className="col-sm-3">Broj</dt>
                                <dd className="col-sm-9">{this.props.order.street_number}</dd>

                                <dt className="col-sm-3">Sprat</dt>
                                <dd className="col-sm-9">{this.props.order.floor}</dd>

                                <dt className="col-sm-3">Broj stana</dt>
                                <dd className="col-sm-9">{this.props.order.apartment_number}</dd>

                                <dt className="col-sm-3">Natpis na interfonu</dt>
                                <dd className="col-sm-9">{this.props.order.intercom_label}</dd>

                                <dt className="col-sm-3">Napomena</dt>
                                <dd className="col-sm-9">{this.props.order.note}</dd>

                                <dt className="col-sm-3">Vreme poručivanja</dt>
                                <dd className="col-sm-9">{this.formatTime(this.props.order.verified ? this.props.order.verified : this.props.order.created)}</dd>

                                <dt className="col-sm-3">Spakovano</dt>
                                {this.props.order.fulfilled &&
                                <dd className="col-sm-9"> {this.formatTime(this.props.order.fulfilled)} </dd>}
                                {!this.props.order.fulfilled &&
                                <dd className="col-sm-9"> Ne </dd>}

                                <dt className="col-sm-3">Poništeno</dt>
                                {this.props.order.canceled &&
                                <dd className="col-sm-9"> {this.formatTime(this.props.order.canceled)} </dd>}
                                {!this.props.order.canceled &&
                                <dd className="col-sm-9"> Ne </dd>}

                                {this.props.order.canceled &&
                                <dt className="col-sm-3">Za ovaj broj telefona ili email adresu je zabranjeno dalje
                                    poručivanje
                                </dt>}
                                {this.props.order.banned_number && this.props.order.canceled &&
                                <dd className="col-sm-9"> Da </dd>}
                                {!this.props.order.banned_number && this.props.order.canceled &&
                                <dd className="col-sm-9"> Ne </dd>}

                                {this.props.order.canceled &&
                                <dt className="col-sm-3">Obrazloženje poništenja</dt>}
                                {this.props.order.canceled &&
                                <dd className="col-sm-9">{this.props.order.ban_note}</dd>
                                }
                            </dl>
                            {this.props.order.missing_article_action && this.props.order.missing_article_action &&
                            <React.Fragment>
                                <strong>Ako nekog od naručenih artikala ne bude na stanju:</strong>
                                {this.props.order.missing_article_action === MISSING_ARTICLE_ACTIONS.IGNORE &&
                                <p>Biće vam isporučen ostatak narudžbine bez tog artikla.</p>
                                }
                                {this.props.order.missing_article_action === MISSING_ARTICLE_ACTIONS.CANCEL &&
                                <p>Vaša narudžbina će biti poništena.</p>
                                }
                                {this.props.order.missing_article_action === MISSING_ARTICLE_ACTIONS.REPLACE &&
                                <p>
                                    Artikal će biti zamenjen istim druge gramaže ili sličnim
                                    artiklom drugog
                                    proizvođača.
                                </p>
                                }
                            </React.Fragment>
                            }
                            {!this.props.order.is_pickup && <React.Fragment>
                                <strong>Služba dostave:</strong>
                                <br/>
                                {this.state.deliveryServices.map((service, index) => (
                                    <React.Fragment key={index}>
                                        <div onClick={this.setDeliveryNumber(service.phone)}>
                                            <input type="radio"
                                                   checked={(!this.props.order.fulfilled && this.state.selectedDeliveryService === service.phone) || this.props.order.delivery_sms_sent_to === service.phone}
                                                   onChange={this.setDeliveryNumber(service.phone)}
                                                   disabled={this.props.order.fulfilled}/>
                                            &nbsp;
                                            {service.name}: {service.phone}
                                        </div>
                                    </React.Fragment>
                                ))}
                            </React.Fragment>}
                            {this.props.order.is_pickup &&
                            <React.Fragment>
                                <strong>Korisnik je odabrao lično preuzimanje</strong>
                                <br/>
                            </React.Fragment>}
                            <br/>
                            {this.props.fulfillOrder &&
                            <Button className="mr-2"
                                    onClick={this.fulfillOrder}
                                    disabled={this.props.order.fulfilled || this.props.order.canceled || (!this.state.selectedDeliveryService && !this.props.order.is_pickup)}>
                                Spakovano
                            </Button>
                            }
                            {this.props.openCancelationDialog &&
                            <Button className="mr-2" variant="danger"
                                    onClick={this.props.openCancelationDialog}
                                    disabled={this.props.order.canceled}>
                                Poništi porudžbinu
                            </Button>
                            }

                            {this.props.verifyOrder &&
                            <Button className="mr-2" variant="info"
                                    onClick={this.props.verifyOrder}>
                                Potvrdi porudžbinu
                            </Button>
                            }
                        </Card.Body>
                    </Card>
                </td>
            </tr>
            }
        </React.Fragment>;
    }
}

Order.propTypes = {
    order: PropTypes.any,
    openOrderId: PropTypes.any,
    openOrder: PropTypes.any,
    addOrderline: PropTypes.func,
    startLoad: PropTypes.func,
    endLoad: PropTypes.func,
    fulfillOrder: PropTypes.any,
    openCancelationDialog: PropTypes.any,
    cancelOrderline: PropTypes.func,
    verifyOrder: PropTypes.func
};

export default Order;
