import React, {Component} from 'react';
import Table from 'react-bootstrap/Table'
import {CartContext} from "../Contexts";
import Button from 'react-bootstrap/Button'
import FormGroup from "react-bootstrap/FormGroup";
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import axios from 'axios'
import {API_URL, ORDER_LIMITS, MISSING_ARTICLE_ACTIONS} from "../Constants";
import VerificationModal from "./VerificationModal/VerificationModal";
import {Link} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import OrderLimitNotice from "./OrderLimitNotice";
import OrdersPerHourLimitNotice from "./OrdersPerHourLimitNotice";

class CheckoutPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customer_name: "",
            email: "",
            phoneNumber: "",
            streetName: "",
            streetNumber: "",
            floor: "",
            apartmentNumber: "",
            intercomLabel: "",
            missingArticleAction: "",
            showModal: false,
            order: {},
            note: "",
            wrongInput: false,
            neighbourhood: "",
            errorResponse: null,
            showNetworkWarning: false,
            numberOfOpenOrders: 0,
            numberOfOrdersInLastHour: 0,
            isPickup: false
        };
        this.phoneNumberRef = React.createRef();
        this.checkForNetworkError();
        this.checkForOpenOrders();
        setInterval(this.checkForOpenOrders, 60000);
        window.addEventListener('storage', this.updateFromStorage);
    }

    componentDidMount() {
        this.getFromStorage();
    }

    getFromStorage = () => {
        const stateFromStorage = localStorage.getItem('checkoutPageState');
        console.log(stateFromStorage)
        if (stateFromStorage) {
            this.setState(JSON.parse(stateFromStorage))
        }
    }

    updateStorage = () => {
        localStorage.setItem('checkoutPageState', JSON.stringify(this.state));
    }

    updateFromStorage = (storageEvent) => {
        if (storageEvent.key === 'checkoutPageState') {
            if (storageEvent.value)
                this.setState(JSON.parse(storageEvent.value))
        }
    }

    emptyStorage = () => {
        localStorage.removeItem('checkoutPageState')
        this.context.emptyCart()
    }

    checkForNetworkError = () => {
        axios({
            method: 'get',
            url: API_URL + 'latestockupdate',
            params: {
                'search': this.state.search
            },
            config: {headers: {"Access-Control-Allow-Origin": "*"}},
            withCredentials: true
        })
            .then(response => {
                this.setState({
                    showNetworkWarning: response.data,
                });
            });
    };

    checkForOpenOrders = () => {
        axios({
            method: 'get',
            url: API_URL + 'ordersnumber',
            config: {headers: {"Access-Control-Allow-Origin": "*"}},
            withCredentials: true
        })
            .then(response => {
                this.setState({
                    numberOfOpenOrders: response.data,
                });
            });
        axios({
            method: 'get',
            url: API_URL + 'ordersnumberhour',
            config: {headers: {"Access-Control-Allow-Origin": "*"}},
            withCredentials: true
        })
            .then(response => {
                this.setState({
                    numberOfOrdersInLastHour: response.data,
                });
            });
    };

    formatPrice = (price) => {
        return price.toLocaleString("sr-RS", {style: "currency", currency: "RSD", minimumFractionDigits: 2})
    };

    removeCartEntry = (index) => {
        let removeArticle = this.context.removeArticle;
        return function () {
            removeArticle(index)
        }
    };

    updatePhoneNumber = (event) => {
        var isnum = /^\d+$/.test(event.target.value);
        if (isnum || event.target.value === "") {
            this.setState({
                phoneNumber: event.target.value,
                wrongInput: false
            });
        }
    };

    sendOrder = (response) => {
        let cart = [];
        this.context.articles.forEach((article) => {
            cart.push(article)
        });

        axios.post(API_URL + 'createorder/', {
            address: this.state,
            cart: cart,
            recaptchaResponse: response
        }, {withCredentials: true})
            .then(response => {
                this.setState({
                    order: response.data
                }, this.updateStorage);
            })
            .catch(error => {
                this.setState({
                    showModal: false,
                    showErrorModal: true,
                    errorResponse: error.response
                });
            });
    };

    closeErrorModal = () => {
        this.setState({
            showErrorModal: false
        });
    };

    openModal = () => {
        if (this.isFormValid()) {
            this.setState({
                showModal: true
            })
        } else {
            this.setState({
                wrongInput: true
            });
        }
    };

    updateStreet = (event) => {
        this.setState({
            streetName: event.target.value,
            wrongInput: false
        });
    };

    updateName = (event) => {
        this.setState({
            customer_name: event.target.value
        });
    };

    updateEmail = (event) => {
        this.setState({
            email: event.target.value,
            wrongInput: false
        });
    };

    updateStreetNumber = (event) => {
        this.setState({
            streetNumber: event.target.value,
            wrongInput: false
        });
    };

    updateFloor = (event) => {
        this.setState({
            floor: event.target.value
        });
    };

    updateApartmentNumber = (event) => {
        this.setState({
            apartmentNumber: event.target.value
        });
    };

    updateIntercomLabel = (event) => {
        this.setState({
            intercomLabel: event.target.value
        });
    };

    updateNote = (event) => {
        this.setState({
            note: event.target.value
        });
    };

    hideModal = () => {
        localStorage.removeItem('checkoutPageState')
        this.setState({
            showModal: false
        })
    };

    isEmailValid = () => {
        const filter = /(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$)/ //eslint-disable-line
        return this.state.email && filter.test(this.state.email);
    };

    isPhoneNumberValid = () => {
        return this.state.phoneNumber && this.state.phoneNumber.startsWith("06") && this.state.phoneNumber.length > 7;
    };

    isFormValid = () => {
        return ((this.state.streetNumber && this.state.streetName && this.state.neighbourhood) || this.state.isPickup) && this.state.missingArticleAction && this.isPhoneNumberValid() && this.isEmailValid()
    };

    updateNeighbourhood = (event) => {
        this.setState({
            neighbourhood: event.target.value,
            wrongInput: false
        });
    };

    updateMissingArticleAction = (event) => {
        this.setState({
            missingArticleAction: event.target.value,
            wrongInput: false
        });
    };

    setIsPickup = (value) => {
        return () => {
            this.setState({
                isPickup: value
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                {Object.keys(this.context.articles).length > 0 && <React.Fragment>
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Naziv</th>
                            <th>Količina</th>
                            <th>Jedinica mere</th>
                            <th>Cena po j.m.</th>
                            <th>Cena</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {this.context.articles.map((cartEntry, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <tr>
                                        <td data-title="Naziv">{cartEntry.article.name}</td>
                                        <td data-title="Količina">{cartEntry.amount}</td>
                                        <td data-title="Jedinica Mere">{cartEntry.article.purchasing_amount_type}</td>
                                        <td data-title="Cena po j.m.">{this.formatPrice(cartEntry.article.price)}</td>
                                        <td data-title="Cena">{this.formatPrice(cartEntry.article.price * cartEntry.amount)}</td>
                                        <td><Button onClick={this.removeCartEntry(index)}>Ukloni iz korpe</Button></td>
                                    </tr>
                                    {cartEntry.article.promotion_description &&
                                    <tr>
                                        <td colSpan={6}>
                                            {cartEntry.article.promotion_description}
                                        </td>
                                    </tr>}
                                </React.Fragment>
                            )
                        })}
                        </tbody>
                    </Table>
                    <dl className="row">
                        <dt className="col-sm-12"><strong className="float-right">Ukupna cena</strong></dt>
                        <dd className="col-sm-12"><strong
                            className="float-right">{this.formatPrice(this.context.calculateCartCost())} +
                            dostava i kese</strong></dd>
                        <dd className="col-sm-12">
                            <p className="float-right">
                                Više informacija o cenama dostave možete dobiti <Link to="/deliverypricelist"
                                                                                      variant="light">ovde</Link>.
                            </p>
                        </dd>
                    </dl>
                    {this.state.showNetworkWarning && <Alert variant="warning">
                        Partnerski market duže od 20 minuta nije poslao informacije o trenutnim cenama i lageru. To može
                        da znači da postoje problemi na mreži zbog kojih vreme obrade porudžbine može biti duže od
                        očekivanog.
                    </Alert>}
                    <Alert variant="danger">
                        Zabranjena je prodaja alkoholnih pića maloletnim licima.
                    </Alert>
                    <h5>Podaci za dostavu</h5>
                    <FormGroup>
                        <Form.Label>Ime naručioca</Form.Label>
                        <Form.Control type="text" value={this.state.name} onChange={this.updateName}/>
                        <Form.Text className="text-muted">
                            Polje nije obavezno. Ime ne mora biti pravo.
                        </Form.Text>
                    </FormGroup>
                    <FormGroup>
                        <Form.Label>Email</Form.Label>
                        <InputGroup>
                            <Form.Control type="email" value={this.state.email} onChange={this.updateEmail}
                                          isInvalid={this.state.wrongInput && !this.isEmailValid()}/>
                        </InputGroup>
                        <Form.Text
                            className={this.state.wrongInput && !this.isEmailValid() ? "text-danger" : "text-muted"}>
                            Polje je obavezno i mora biti ispravna email adresa.
                        </Form.Text>
                    </FormGroup>
                    <FormGroup>
                        <Form.Label>Broj telefona</Form.Label>
                        <InputGroup>
                            <Form.Control type="text" value={this.state.phoneNumber} onChange={this.updatePhoneNumber}
                                          ref={this.phoneNumberRef}
                                          isInvalid={this.state.wrongInput && !this.isPhoneNumberValid()}/>
                        </InputGroup>
                        <Form.Text
                            className={this.state.wrongInput && !this.isPhoneNumberValid() ? "text-danger" : "text-muted"}>
                            Broj mobilnog telefona je neophodan. Mora počinjati sa "06" i
                            imati barem 8 karaktera.
                        </Form.Text>
                    </FormGroup>
                    <FormGroup>
                        <Form.Label>Da li želite dostavu ili lično preuzimanje u Mikromarketu u ulici Geri Karolja
                            33?</Form.Label>
                        <InputGroup>
                            <div onClick={this.setIsPickup(false)}>
                                <input type="radio"
                                       checked={!this.state.isPickup}
                                       onChange={this.setIsPickup(false)}/>
                                &nbsp;
                                Želim dostavu
                            </div>
                        </InputGroup>
                        <InputGroup>
                            <div onClick={this.setIsPickup(true)}>
                                <input type="radio"
                                       checked={this.state.isPickup}
                                       onChange={this.setIsPickup(true)}/>
                                &nbsp;
                                Želim lično da preuzmem porudžbinu
                            </div>
                        </InputGroup>
                    </FormGroup>
                    {!this.state.isPickup && <React.Fragment>
                        <FormGroup>
                            <Form.Label>Deo grada</Form.Label>
                            <Form.Control as="select" value={this.state.neighbourhood}
                                          onChange={this.updateNeighbourhood}
                                          isInvalid={this.state.wrongInput && !this.state.neighbourhood}>
                                <option></option>
                                <option>Adamovićevo naselje</option>
                                <option>Adice</option>
                                <option>Avijatičarsko naselje</option>
                                <option>Banatić</option>
                                <option>Centar - Stari grad</option>
                                <option>Detelinara</option>
                                <option>Futog</option>
                                <option>Grbavica</option>
                                <option>Kamenjar</option>
                                <option>Klisa</option>
                                <option>Limani 1 i 2</option>
                                <option>Limani 3 i 4</option>
                                <option>Lipov gaj</option>
                                <option>Novo naselje</option>
                                <option>Podbara</option>
                                <option>Petrovaradin</option>
                                <option>Rotkvarija</option>
                                <option>Sajlovo</option>
                                <option>Sajmište</option>
                                <option>Salajka</option>
                                <option>Satelit</option>
                                <option>Slana Bara</option>
                                <option>Sremska Kamenica</option>
                                <option>Telep</option>
                                <option>Veternik</option>
                                <option>Vidovdansko naselje</option>
                            </Form.Control>
                            {this.state.wrongInput && !this.state.neighbourhood && <Form.Text className="text-danger">
                                Polje je obavezno.
                            </Form.Text>}
                        </FormGroup>
                        <FormGroup>
                            <Form.Label>Ulica</Form.Label>
                            <Form.Control type="text" value={this.state.streetName}
                                          onChange={this.updateStreet}
                                          isInvalid={this.state.wrongInput && !this.state.streetName}/>
                            {this.state.wrongInput && !this.state.streetName && <Form.Text className="text-danger">
                                Polje je obavezno.
                            </Form.Text>}
                        </FormGroup>
                        <FormGroup>
                            <Form.Label>Broj</Form.Label>
                            <Form.Control type="text" value={this.state.streetNumber}
                                          onChange={this.updateStreetNumber}
                                          isInvalid={this.state.wrongInput && !this.state.streetNumber}/>
                            {this.state.wrongInput && !this.state.streetNumber && <Form.Text className="text-danger">
                                Polje je obavezno.
                            </Form.Text>}
                        </FormGroup>
                        <FormGroup>
                            <Form.Label>Sprat</Form.Label>
                            <Form.Control type="text" value={this.state.floor} onChange={this.updateFloor}/>
                            <Form.Text className="text-muted">
                                Polje nije obavezno.
                            </Form.Text>
                        </FormGroup>
                        <FormGroup>
                            <Form.Label>Stan</Form.Label>
                            <Form.Control type="text" value={this.state.apartmentNumber}
                                          onChange={this.updateApartmentNumber}/>
                            <Form.Text className="text-muted">
                                Polje nije obavezno.
                            </Form.Text>
                        </FormGroup>
                        <FormGroup>
                            <Form.Label>Natpis na interfonu</Form.Label>
                            <Form.Control type="text" value={this.state.intercomLabel}
                                          onChange={this.updateIntercomLabel}/>
                            <Form.Text className="text-muted">
                                Polje nije obavezno.
                            </Form.Text>
                        </FormGroup>
                    </React.Fragment>}
                    <FormGroup>
                        <Form.Label>Ukoliko nekog od naručenih artikala nema na stanju, da li želite da vam se isporuči
                            ostatak narudžbine bez tog artikla, sličan artikal drugog proizvođača ili druge gramaže ili
                            želite da poništite narudžbinu? </Form.Label>
                        <Form.Control as="select" value={this.state.missingArticleAction}
                                      onChange={this.updateMissingArticleAction}
                                      isInvalid={this.state.wrongInput && !this.state.missingArticleAction}>
                            <option></option>
                            <option value={MISSING_ARTICLE_ACTIONS.IGNORE}>Bez tog artikla</option>
                            <option value={MISSING_ARTICLE_ACTIONS.REPLACE}>Sličan artikal
                            </option>
                            <option value={MISSING_ARTICLE_ACTIONS.CANCEL}>Poništenje narudžbine</option>
                        </Form.Control>
                        {this.state.wrongInput && !this.state.missingArticleAction &&
                        <Form.Text className="text-danger">
                            Polje je obavezno.
                        </Form.Text>}
                    </FormGroup>
                    <FormGroup>
                        <Form.Label>Napomena</Form.Label>
                        <Form.Control as="textarea" value={this.state.note} onChange={this.updateNote}/>
                        <Form.Text className="text-muted">
                            Polje nije obavezno.
                        </Form.Text>
                    </FormGroup>
                    {this.state.wrongInput && <h6 className="text-danger">Formular nije pravilno popunjen.</h6>}
                    <OrderLimitNotice/>
                    {/*<OrdersPerHourLimitNotice/>*/}
                    {this.state.numberOfOpenOrders < ORDER_LIMITS.disallowOrder &&
                    // {this.state.numberOfOrdersInLastHour < ORDER_LIMITS.maxPerHour &&
                    <Button onClick={this.openModal} disabled={false}>Potvrdite porudžbinu
                    </Button>
                    }
                    {/*}*/}
                </React.Fragment>}
                {Object.keys(this.context.articles).length === 0 && <React.Fragment>
                    <br/>
                    <h4>Nemate nijedan artikal u korpi</h4>
                    <p> Vratite se u <Link to={"/"}>prodavnicu</Link> i dodajte neki artikal </p>
                </React.Fragment>}
                {this.state.showModal &&
                <VerificationModal show={this.state.showModal} onHide={this.hideModal}
                                   email={this.state.email} order={this.state.order}
                                   phoneNumber={this.state.phoneNumber}
                                   sendOrderCallback={this.sendOrder} verificationCallback={this.emptyStorage}/>}
                <Modal show={this.state.showErrorModal} onHide={this.closeErrorModal} backdrop='static'>
                    <Modal.Header closeButton>
                        <Modal.Title className="text-danger">Došlo je do greške</Modal.Title>
                    </Modal.Header>
                    {(!this.state.errorResponse || !this.state.errorResponse.data || this.state.errorResponse.data.error !== 'SMS_TIME') &&
                    <Modal.Body>
                        Porudžbina nije uspešno napravljena. Molimo pokušajte ponovo.
                    </Modal.Body>}
                    {(this.state.errorResponse && this.state.errorResponse.data && this.state.errorResponse.data.error === 'SMS_TIME') &&
                    <Modal.Body>
                        Ponovno slanje verifikacionog koda je moguće
                        za {this.state.errorResponse.data.seconds} sekundi.
                    </Modal.Body>}
                </Modal>
            </React.Fragment>
        )
    }
}

CheckoutPage.contextType = CartContext;
export default CheckoutPage;
