import React, {Component} from 'react';
import axios from "axios";
import {API_URL} from "../Constants";
import Spinner from "react-bootstrap/Spinner";
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AuthContext} from "../Contexts";
import {FormGroup, Form, InputGroup, Modal} from "react-bootstrap";
import Fuse from "fuse.js";
import Order from "./components/Order"
import * as PropTypes from "prop-types";


class OrderCancelationConfirmationDialog extends Component {
    render() {
        return <Modal show={this.props.show} onHide={this.props.onHide}>
            <Modal.Header closeButton>
                {this.props.orderToCancel && <Modal.Title>Potvrdite poništenje porudžbine
                    za {this.props.orderToCancel.street} {this.props.orderToCancel.street_number}</Modal.Title>}
            </Modal.Header>
            <Modal.Body>
                {this.props.orderToCancel && <div>
                    <Form.Group>
                        <Form.Label>
                            Obrazloženje:
                        </Form.Label>
                        <Form.Control value={this.props.value} onChange={this.props.onChange}
                                      type="text"
                                      as='textarea' maxLength={300}
                                      isInvalid={this.props.invalid}/>
                        {this.props.invalid && <Form.Text className="text-danger">
                            Polje je obavezno.
                        </Form.Text>}
                    </Form.Group>
                    <Form.Group>
                        <Form.Check type='checkbox' checked={this.props.checked}
                                    label="Zabrani ponovno naručivanje ovom korisniku"
                                    onChange={this.props.onChange1}/>
                    </Form.Group>
                </div>}

            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={this.props.onClick}>Poništi porudžbinu</Button>
            </Modal.Footer>

        </Modal>;
    }
}

OrderCancelationConfirmationDialog.propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func,
    orderToCancel: PropTypes.any,
    value: PropTypes.any,
    onChange: PropTypes.func,
    invalid: PropTypes.bool,
    checked: PropTypes.any,
    onChange1: PropTypes.func,
    onClick: PropTypes.func,
    fulfillOrder: PropTypes.func
};

class OrdersTab extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            orders: [],
            loading: true,
            openOrderId: null,
            search: "",
            filteredOrders: [],
            loadingModalOpen: false,
            cancelNote: "",
            banNumber: false,
            showCancellationDialog: false,
            orderToCancel: null,
            badInputInCancellationDialog: false
        };
        this.loadOrders();
        setInterval(this.reloadOrders, 60000);
    }

    loadOrders = () => {
        axios({
            method: 'get',
            url: API_URL + 'orders/',
            headers: {
                'Authorization': this.context.getAuthString()
            }
        })
            .then(response => {
                //handle success
                this.setState({
                    orders: response.data,
                    loading: false,
                    filteredOrders: response.data
                });
            });
    };

    reloadOrders = () => {
        axios({
            method: 'get',
            url: API_URL + 'orders/',
            headers: {
                'Authorization': this.context.getAuthString()
            }
        })
            .then(response => {
                //handle success
                this.setState({
                    orders: response.data,
                }, () => {
                    this.searchOrders()
                });
            });
    };

    markAsLoading = () => {
        this.setState({
            loadingModalOpen: true
        })
    };

    unmarkAsLoading = () => {
        this.setState({
            loadingModalOpen: false
        })
    };

    addOrderline = (orderId, orderline) => {
        let orders = this.state.orders;
        for (const order of orders) {
            if (order.id === orderId) {
                order.orderlines.push(orderline)
            }
        }
        this.setState({
            orders: orders
        })
    };

    formatTime = (timestring) => {
        // return new Date(timestring).toLocaleString('en-GB', {timeZone: 'UTC'})
        return new Date(timestring).toLocaleString('sr-RS')
    };

    markOrderlineAsCanceled = (order, orderline) => {
        let orders = this.state.orders;
        for (const orderTemp of orders) {
            if (orderTemp.id === order.id) {
                for (const orderlineTemp of orderTemp.orderlines) {
                    if (orderlineTemp.id === orderline.id)
                        orderlineTemp.canceled = orderline.canceled
                }
            }
        }
        this.setState({
            orders: orders
        })
    };

    cancelOrderline = (order, orderline) => {
        const orderlineId = orderline.id;
        const markOrderlineAsCanceled = this.markOrderlineAsCanceled;
        const self = this;
        return function () {
            self.markAsLoading();
            axios.post(API_URL + 'orderlines/' + orderlineId + '/cancel', {}, {
                headers: {
                    'Authorization': self.context.getAuthString()
                }
            })
                .then(response => {
                    markOrderlineAsCanceled(order, response.data);
                    self.unmarkAsLoading();
                })
                .catch(error => {
                    console.log(error);
                    alert("Akcija nije uspela");
                    self.unmarkAsLoading();
                });
        }
    };

    updateOrderState = (newOrder) => {
        let orders = this.state.orders;
        for (const order of orders) {
            if (order.id === newOrder.id) {
                order.canceled = newOrder.canceled;
                order.fulfilled = newOrder.fulfilled;
                order.delivery_sms_sent_to = newOrder.delivery_sms_sent_to
            }
        }
        this.setState({
            orders: orders
        })
    };

    cancelOrder = () => {
        if (!this.state.cancelNote) {
            this.setState({
                badInputInCancellationDialog: true
            })
        } else {
            const markAsLoading = this.markAsLoading;
            const unmarkAsLoading = this.unmarkAsLoading;
            const updateOrderState = this.updateOrderState;
            markAsLoading();
            axios.post(API_URL + 'orders/' + this.state.orderToCancel.id + '/cancel', {
                ban_number: this.state.banNumber,
                note: this.state.cancelNote
            }, {
                headers: {
                    'Authorization': this.context.getAuthString()
                }
            })
                .then(response => {
                    updateOrderState(response.data);
                    this.setState({
                        banNumber: false,
                        cancelNote: "",
                        showCancellationDialog: false,
                    });
                    unmarkAsLoading();
                })
                .catch(error => {
                    console.log(error);
                    alert("Akcija nije uspela");
                    unmarkAsLoading();
                });
        }
    };

    fulfillOrder = (order) => {
        const markAsLoading = this.markAsLoading;
        const unmarkAsLoading = this.unmarkAsLoading;
        let orderId = order.id;
        const updateOrderState = this.updateOrderState;
        const self = this;
        return function (deliveryServiceNumber) {
            markAsLoading();
            axios.post(API_URL + 'orders/' + orderId + '/fulfill',
                "delivery_service_number=".concat(deliveryServiceNumber)
                , {
                    headers: {
                        'Authorization': self.context.getAuthString()
                    }
                })
                .then(response => {
                    updateOrderState(response.data);
                    unmarkAsLoading();
                })
                .catch(error => {
                    console.log(error);
                    alert("Akcija nije uspela");
                    unmarkAsLoading();
                });
        }
    };

    openOrder = (order) => {
        let orderId = order.id;
        let self = this;
        return function () {
            if (self.state.openOrderId === orderId) {
                self.setState({
                    openOrderId: null
                })
            } else {
                self.setState({
                    openOrderId: orderId
                })
            }
        }
    };

    formatPrice = (price) => {
        return price.toLocaleString("sr-RS", {style: "currency", currency: "RSD", minimumFractionDigits: 2})
    };

    updateSearchTerm = (event) => {
        this.setState({
            search: event.target.value,
        }, () => {
            this.searchOrders()
        });

    };

    searchOrders = () => {
        let result = this.state.orders;
        if (this.state.search) {
            const options = {
                shouldSort: true,
                threshold: 0.6,
                location: 0,
                distance: 100,
                maxPatternLength: 32,
                minMatchCharLength: 1,
                keys: [
                    "street",
                    "street_number"
                ]
            };
            const fuse = new Fuse(this.state.orders, options); // "list" is the item array
            result = fuse.search(this.state.search);
        }
        this.setState({
            filteredOrders: result
        });
    };

    openCancellationDialog = (order) => {
        const self = this;
        return function () {
            self.setState({
                showCancellationDialog: true,
                orderToCancel: order
            })
        };

    };

    closeCancellationDialog = () => {
        this.setState({
            showCancellationDialog: false
        })
    };

    updateCancelNote = (event) => {
        this.setState({
            cancelNote: event.target.value,
            badInputInCancellationDialog: false
        })
    };

    updateBanNumber = (event) => {
        this.setState({
            banNumber: event.target.checked
        })
    };

    render() {
        return (
            <div>
                {this.state.loading && <Spinner animation="border" variant="primary" size="lg"
                                                style={{
                                                    height: "150px",
                                                    width: "150px",
                                                    align: "center",
                                                    marginLeft: "auto",
                                                    marginRight: "auto",
                                                    display: "block"
                                                }}/>}
                {!this.state.loading && <React.Fragment>
                    <FormGroup>
                        <Form.Label className="mt-2">Pretraga porudžbina po adresi :</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon={faSearch}/>
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control value={this.state.search} placeholder="Unesite adresu"
                                          onChange={this.updateSearchTerm} autoFocus>
                            </Form.Control>
                        </InputGroup>
                    </FormGroup>
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Adresa</th>
                            <th>Vreme</th>
                            <th>Telefon</th>
                            <th>Cena</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.filteredOrders.map((order, index) =>
                            <Order key={index}
                                   order={order}
                                   openOrderId={this.state.openOrderId}
                                   openOrder={this.openOrder(order)}
                                   addOrderline={this.addOrderline}
                                   startLoad={this.markAsLoading}
                                   endLoad={this.unmarkAsLoading}
                                   fulfillOrder={this.fulfillOrder(order)}
                                   openCancelationDialog={this.openCancellationDialog(order)}
                                   cancelOrderline={this.cancelOrderline}/>
                        )}
                        </tbody>
                    </Table>
                    <Modal show={this.state.loadingModalOpen}>
                        <Modal.Header>
                            <Modal.Title>Molimo sačekajte</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Spinner animation="border" variant="primary" size="lg"
                                     style={{
                                         height: "150px",
                                         width: "150px",
                                         align: "center",
                                         marginLeft: "auto",
                                         marginRight: "auto",
                                         display: "block"
                                     }}/>
                        </Modal.Body>

                    </Modal>
                    <OrderCancelationConfirmationDialog show={this.state.showCancellationDialog}
                                                        onHide={this.closeCancellationDialog}
                                                        orderToCancel={this.state.orderToCancel}
                                                        value={this.state.cancelNote} onChange={this.updateCancelNote}
                                                        invalid={this.state.badInputInCancellationDialog}
                                                        checked={this.state.banNumber} onChange1={this.updateBanNumber}
                                                        onClick={this.cancelOrder}/>
                </React.Fragment>
                }
            </div>
        );
    }
}

OrdersTab.contextType = AuthContext;

export default OrdersTab;
